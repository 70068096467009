import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  public usertitle = 'Nuevo empleado';
  public isCollapse = false;
  public newUser = true;
  abecedario = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z","0","1","2","3","4","5","6","7","8","9",".","-","_","$","&","#","@"];
  user:User;
  saveUser;
  goList;
  submitted = false;
  userForm:FormGroup;
  constructor(
    private activatedroute: ActivatedRoute,
    public _ss: SharingService,
    private _api:ApiService,
    private _location: Location,
    private formBuilder:FormBuilder,
    private _ns:NotifierService
  ) {
    _ss.setSectionTitle("Nuevo empleado");
    _ss.saveButton = true;
    _ss.newButton = false;
    _ss.listButton = true;

    this.userForm = this.formBuilder.group({
      id:[null],
      company_id:[this._ss.getUserSettings().company_id],
      nif:['',Validators.required],
      name:['',Validators.required],
      surnames:['',Validators.required],
      password:[''],
      worker_number:[0],
      hours_week:[0],
      type:[0],
      email:['',Validators.required],
      phone:[null],
      status:[true],
      alerts:[false]
    })

    if(activatedroute.snapshot.params.id){
      this.userForm.controls['id'].setValue(activatedroute.snapshot.params.id);
      this.newUser = false;
    }    
   }
   

  ngOnInit(): void {
    this.saveUser = this._ss.$save.subscribe(()=>{
      (this.newUser) ? this.saveNewEmployee() : this.modifyEmployee();
    });

    this.goList = this._ss.$golist.subscribe(()=>{
      this._location.back();
    });

    if(this.newUser != true){
      let id = this.userForm.controls['id'].value;
      this._ss.setSectionTitle('Editar Empleado');
      this.getEmployeeDetails(id);
    }else{
      this.generatePassword();
    }
  }

  get f() { return this.userForm.controls; }

  showDetails(){
    console.log("Abriendo / cerrando detalles de usuario");
    this.isCollapse = !this.isCollapse;
  }

  saveNewEmployee(){
    console.log("Guarda usuario");
    if(!this.userForm.invalid){
      this.user = this.userForm.value;
      console.log(this.user);
      this._api.addCompanyEmployee(this.user).subscribe((response)=>{
        if(response.success == 'OK'){
          this._ns.notify('success','Empleado creado correctamente!');
          this.userForm.reset();
          this.userForm.patchValue({
            worker_number:0,
            hours_week:0,
            type:0,
            phone:null,
            status:true,
            alerts:false
          });
          this.generatePassword();
        }else{
          this._ns.notify('error','Erro: No se ha podido crear el empleado!');
        }
      },(err)=>{
        this._ss.errorHandler(err);
      });
    }else{
      console.log("Formulario invalido");
      this._ns.notify('error','Oops, algunos campos son obligatorios');
    }
    
  }

  modifyEmployee(){
    console.log(this.userForm.value);
    this._api.setCompanyEmployee(this.userForm.value).subscribe((response)=>{
      if(response){
        this._ns.notify('success','Empleado modificado correctamente!');
      }else{
        this._ns.notify('error','Erro: No se ha podido crear el empleado!');
      }
    },(err)=>{
      this._ss.errorHandler(err);
    });
  }


  getEmployeeDetails(id:number){
    this._api.getCompanyEmployeeDetails(id).subscribe((response)=>{
      if(response){
        console.log(response);
        this.userForm.patchValue({
          id: response.id,
          company_id: response.company_id,
          type:response.type,
          nif:response.nif,
          name: response.name,
          surnames: response.surnames,
          worker_number: response.worker_number,
          hours_week: response.hours_week,
          phone: response.phone,
          email: response.email,
          status: response.status,
          alerts: response.alerts
        });
        this.usertitle = this.userForm.controls['name'].value + ' '+this.userForm.controls['surnames'].value+' ( '+this.userForm.controls['nif'].value+' )';
      }else{
        this._ns.notify('error', 'Error: al cargar los datos del empleado');
      }
    },(err)=>{
      this._ss.errorHandler(err);
    });
  }

  generatePassword(){
    this.userForm.controls['password'].setValue(this.getPassword());
  }
  


  getPassword(){
    let password:String='';
    for(var i = 0; i < 8; i++){
      password += this.abecedario[(Math.floor(Math.random()*this.abecedario.length))];
    }
    return password;
  }

  ngOnDestroy(){
    this.saveUser.unsubscribe();
    this.goList.unsubscribe();
  }
}
