<div class="content">
    <div class="wrap">
        <div class="button-bar">
            <div class="left">
                <button (click)="getEmployeeJournal()" [disabled]="!employee.days.length"><i class="far fa-file-pdf"></i> DESCARGAR PDF</button>
                <button disabled><i class="far fa-file-excel"></i>DESCARGAR EXCEL</button>
            </div>
            <div class="center">
                <h1 class="counter">{{counter.hours}} <span>h</span> {{counter.minutes}} <span>min</span></h1>
            </div>
            <div class="right">
                <mat-form-field>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker"  >
                        <input matStartDate #dateRangeStart formControlName="start" placeholder="Fecha Inicio" (click)="$event.target.select()" (dateChange)="tryToSelectDateRange()">
                        <input matEndDate #dateRangeEnd formControlName="end" placeholder="Fecha Final" (click)="$event.target.select()" (dateChange)="tryToSelectDateRange()">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker panelClass="btn-date"></mat-date-range-picker>
                    
                    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fech. Inicio incorrecta</mat-error>
                    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fech. Fin incorrecta</mat-error>
                </mat-form-field>
            </div>
        </div>
        <ngx-datatable 
        rowHeight="auto"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [limit]="10"
        [rows]="employee.days"
        (activate)='onClick($event)' 
        class="material">
            <ngx-datatable-column prop="day" name="DÍA">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{value | date:'EEEE, d MMM YYYY'}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="day" name="TIEMPO">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{row.hours}}<span class="hour">h</span>
                    {{row.minutes}}<span class="min">min</span>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
