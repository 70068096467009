import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';
import * as moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class ExpensesComponent implements OnInit {

  newExpense;
  expenses = [];

  range = new FormGroup({
    start: new FormControl,
    end: new FormControl
  })

  constructor(
    public _ss: SharingService,
    private _api :ApiService,
    private _router: Router
  ) { 
    this._ss.newButton = true;
    this._ss.saveButton = false;
    this._ss.listButton = false;
    this.newExpense = this._ss.$create.subscribe(() => {
      this._router.navigateByUrl("/gastos/nuevo");
    });
    this._ss.setSectionTitle('Gastos Empresa');
    this.range.controls['start'].setValue(moment().clone().startOf('month').format('YYYY-MM-DD'));
    this.range.controls['end'].setValue(moment().clone().endOf('month').format('YYYY-MM-DD'));
  }

  ngOnInit(): void {
    this.getCompanyExpenses(this.range.controls.start.value, this.range.controls.end.value);

  }
  

  getCompanyExpenses(startDate, endDate){
    this._api.getCompanyExpenses(startDate, endDate).subscribe((response)=>{
      if(response){
        this.expenses = response;
        this.expenses.forEach(elt => {
          elt.checked = false;
        });
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  tryToSelectDateRange():void{
    if(this.range.controls['end'].value != null){
      let startDate = moment(this.range.controls['start'].value).format('YYYY-MM-DD');
      let endDate = moment(this.range.controls['end'].value).format('YYYY-MM-DD');
      this.getCompanyExpenses(startDate, endDate);
    }
  }

  goToExpenseDetails(e){
    console.log(e)
    this._router.navigateByUrl('/gastos/empleado/'+e);
  }

 

  ngOnDestroy(){
    this.newExpense.unsubscribe();
  }
}
