import { Component, OnInit } from '@angular/core';
import { menu } from 'src/app/constants';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menulinks = menu;

  username:String;

  constructor(
    public _ss:SharingService
  ) { 
    this.username = this._ss.getUserSettings().name;
  }

  ngOnInit(): void {
    if(this._ss.getUserSettings().type != 1){
      this.menulinks.find(x => x.title == 'Empleados').active = false;
      this.menulinks.find(x => x.title == 'Centros').active = false;
      this.menulinks.find(x => x.title == 'Opciones').active = false;
      this.menulinks.find(x => x.title == 'Alertas').active = false;
      this.menulinks.find(x => x.title == 'Historial').active = true;
      this.menulinks.find(x => x.title == 'Gastos Empresa').active = false;
      this.menulinks.find(x => x.title == 'Gastos').active = true;
    }
  }

  openMenu(){
    console.log("Abriendo menu...", this._ss.isOpen);
    this._ss.isOpen = !this._ss.isOpen;

  }

}
