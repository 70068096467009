<div class="timetable">
    <div class="title">
        <h1><i class="far fa-clock"></i>Horario</h1>
        <span class="add-timetable" (click)="addTimeTable(true, null, null)"><i class="far fa-clock"></i>Añadir horario</span>
    </div>
   
    <div class="details">
        <div class="day" *ngFor="let day of timetable">
            <h1>{{day.name}}</h1>
            <div class="hour-info" *ngIf="!day.hours.length ">
                <span>No tiene horario</span>
            </div>
            <div *ngFor="let hour of day.hours">
                <div class="hour-container">
                    <span class="btn-delete" (click)="removeHour(day.week_day, hour.id)"><i class="fas fa-times"></i></span>
                    <div class="hours" (click)="addTimeTable(false, day.week_day, hour.id)">
                        <p>{{hour.hour_in}}</p>
                        <p>{{hour.hour_out}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-add-timetable *ngIf="!showAddTimeTable" [iduser]="iduser" [idcenter]="idcenter" [day]="day" (onCancelTimetable)="addTimeTable()"></app-add-timetable>