import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { User } from 'src/app/models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharingService {
  public imageUrl = environment.staticURL;
  public currentUser: any;
  storageName = 'currentUser';
  loading = false;
  loadingText = 'Cargando...';
  loadingProgress = '';
  isOpen = false;
  sectionTitle="";
  language = '1';
  key='';
  superadminkey = 'admin';
  isSuperAdmin = false;

  refreshtable = false;

  //Theme Options
  public marginLeft = '58px';
  public padding = '25px';

  //Buttons
  listButton = false;
  saveButton = false;
  newButton = false;

  $save = new EventEmitter();
  $create = new EventEmitter();
  $golist = new EventEmitter();
 

  constructor(
    private _ns:NotifierService,
    private router: Router
  ) { }

  saveEvent(){
    console.log("Guardando....");
    this.$save.emit();
  }

  createEvent(){
    console.log("Creando....");
    this.$create.emit();
  }

  goListEvent(){
    console.log("Redirigiendo....");
    this.$golist.emit();
  }

  setSuperAdmin(status){
    this.key = '';
    this.isSuperAdmin = status;
  }

  isLoged():boolean {
    if (this.getUserSettings() != null && this.getUserSettings().id && this.getUserSettings().id > 0){
      this.marginLeft = "58px";
      this.padding = "25px";  
      return true;
    }
    return false;
    }

    logout(){
      this.cleanAll();
      this.router.navigateByUrl("/login");
    }

    cleanAll(){
        localStorage.removeItem(this.storageName);
        localStorage.clear();
    }

    getUserSettings(): User {
        const userData = localStorage.getItem(this.storageName);
        return JSON.parse(userData);
    }

    setUserSettings(userData) {
        localStorage.setItem(this.storageName, JSON.stringify(userData));
        this.currentUser = userData;
    }

    isLoading(){
        return this.loading
    }

    decode(){
      let code = this.getUserSettings();
      return code;
    }

    notify(type, errorMsg, error?) {
      if (type == 'error' && error) { console.log(error); }
      this._ns.notify(type, errorMsg);
    }

    setSectionTitle(title){
      this.sectionTitle = title;
    }



    errorHandler(e){
      console.log("Se ha producido un error: "+e.message);
      this._ns.notify('error','Oops, Lo sentimos se ha producido un error!');
    }
}

