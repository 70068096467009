import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-journal-options',
  templateUrl: './journal-options.component.html',
  styleUrls: ['./journal-options.component.scss']
})
export class JournalOptionsComponent implements OnInit {
  title='No se ha encontrado la localización';

  @Input() options:any;
  @Output() onSaveOption = new EventEmitter<any>();

  formOption:FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    private _ns: NotifierService
  ) {
    this.formOption = this.formBuilder.group({
      id:[null, Validators.required],
      type:[null]
    });
   }

  ngOnInit(): void {
    console.log(this.options);
  }

  switchCheckOption(option){
    this.formOption.controls.type.setValue(option.options[option.selectedIndex].getAttribute('data-type'));
  }

  saveSelection(){
    if(!this.formOption.invalid){
      console.log(this.formOption.value);
      this.onSaveOption.emit(this.formOption.value);
    }else{
      this._ns.notify('error','Error: Debe seleccionar al menos una opción.');
    }
  }

}
