import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { SharingService } from './sharing.service';
import { User } from '../models/user';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = environment.apiURL;
  private adminUrl = environment.apiURL + environment.adminURL;
  private expenseUrl = environment.apiURL + '/expenses';
  private holidaysUrl = environment.apiURL + '/absences';
  public uploadUrl = environment.uploadURL;

  constructor(private http: Http,
    private _ss:SharingService) { }

  private getHeadersPublic() {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('api-key', 'abc123.');
      return headers;
  }

  private errorHandler(error: any): void {
    console.log('Se ha producido un error' + error);
  }


  /* Users */  

  // Cargar todos los usuarios de la empresa
  getCompanyEmployees(): Observable <any> {
    return this.http.get(this.adminUrl + '/company/'+this._ss.getUserSettings().company_id+'/users',
    {headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }
   
  // Guardar un empleado nuevo en la empresa
  addCompanyEmployee(user:User): Observable <any> {
    return this.http.post(this.adminUrl + '/company/'+this._ss.getUserSettings().company_id+'/users/new', user,
    {headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Modificar un empleado existente
  setCompanyEmployee(user:User): Observable <any> {
    return this.http.put(this.adminUrl + '/company/'+this._ss.getUserSettings().company_id+'/users', user,
    {headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Cargar los detalles de un usuario de la empresa
  getCompanyEmployeeDetails(id:number): Observable <any>{
    return this.http.get(this.adminUrl+'/company/'+this._ss.getUserSettings().company_id+'/users/'+id,
    {headers: this.getHeadersPublic()})
    .map((res: Response)=> res.json())
    .catch((e)=> Observable.throw(this.errorHandler(e)));
  }

  /* Centers */
   
  getCompanyCenters(): Observable <any> {
    return this.http.get(this.adminUrl + '/company/'+this._ss.getUserSettings().company_id+'/offices',
    {headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  /* Options */

   // Cargar opciones de ausencias de la compañia activas
   getJournalOptions(id_user:number){
    return this.http.get(this.adminUrl + '/company/'+this._ss.getUserSettings().company_id+'/journal_options',
    {headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Cargar opciones de ausencias de la compañia activas
  getJournalActiveOptions(id_user:number){
    return this.http.get(this.baseUrl + '/journal/'+id_user+'/offices_options',
    {headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Añadir opciones de ausencias
  addJournalOption(option:any): Observable <any> {
    return this.http.post(this.adminUrl + '/company/'+this._ss.getUserSettings().company_id+'/journal_options', option,
    {headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Modificar opciones de ausencias
  setJournalOption(option:any): Observable <any>{
    return this.http.put(this.adminUrl + '/company/'+this._ss.getUserSettings().company_id+'/journal_options', option,
    {headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Setear una opción de fichaje cuando no tiene activada la localización
  putJournalOption(data:any): Observable <any>{
    return this.http.put(this.baseUrl+'/journal/option',data,
    {headers: this.getHeadersPublic()})
    .map((res)=> res.json())
    .catch((e)=> Observable.throw(this.errorHandler(e)));
  }

  /* Configuración */

  // Cambiar contraseña 
  changePass(config:any): Observable <any> {
    return this.http.put(this.adminUrl + '/password/change', {password: config.password, new_password: config.new_password, id_user: this._ss.getUserSettings().id}, 
    {headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  /* Checkin / Checkout */

  // Cargar los fichajes del empleado en el día actual
  getUserCheckInDay(id: number, date: string): Observable <any>{
    return this.http.get(this.baseUrl+'/user/'+id+'/date/'+date,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Fichar hora entrada
  checkInTime(id: number, coords): Observable <any>{
    return this.http.put(this.baseUrl+'/check',{iduser: id, longin: coords.longitude, latin: coords.latitude, date:moment().format('YYYY-MM-DD HH:mm:ss')},
    {headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Fichar hora salida
  checkOutTime(id:number, iduser: number, coords:any): Observable <any>{
    return this.http.put(this.baseUrl+'/journal',{idjournal: id, iduser: iduser, longout: coords.longitude, latout: coords.latitude, date:moment().format('YYYY-MM-DD HH:mm:ss')},
    {headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  /* Histórico de Jornada Laboral */

  // Modificar jornada laboral
  setJournalEmployee(journal:any){
    return this.http.put(this.adminUrl+'/journal/'+journal.id+'/edit', journal,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Cargar historico de un empleado
  getUserHistory(id: number): Observable <any>{
    return this.http.get(this.baseUrl+'/history/'+id,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Cargar un historico de un empleado por mes
  CheckWorksDayOfMonth(firstdaymonth: string, lastdaymonth: string, iduser: number): Observable <any>{
    return this.http.get(this.baseUrl+'/journal/'+iduser+'/checkin/'+firstdaymonth+'/checkout/'+lastdaymonth,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Cargar entradas y salidas de un empleado en un dia
  getUserChecksInDay(day, id_user){
    return this.http.get(this.adminUrl+'/company/'+this._ss.getUserSettings().company_id+'/users/'+id_user+'/day/'+day,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  /* Alertas */

  // Cargar alertas del centro dado un rago de fechas determinado
  getCompanyAlerts(startDate, endDate){
    return this.http.get(this.adminUrl+'/company/'+this._ss.getUserSettings().company_id+'/alerts/from/'+startDate+'/to/'+endDate,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Eliminar alertas seleccionadas del centro
  deleteCompanyAlert(alerts: any){
    return this.http.put(this.adminUrl+'/company/'+this._ss.getUserSettings().company_id+'/deletealerts', {ids:alerts},
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Cargar configuración de alertas del centro
  getCompanyAlertsConfig(){
    return this.http.get(this.adminUrl+'/company/'+this._ss.getUserSettings().company_id+'/alerts/config',
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Actualizar configuracion de alertas del centro
  setCompanyAlertsConfig(alerts:any){
    return this.http.put(this.adminUrl+'/company/'+this._ss.getUserSettings().company_id+'/alerts/config', alerts,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  /* Horario */


  // Cargar horario del empleado
  getUserTimetable(user_id:number){
    return this.http.get(this.adminUrl+'/company/'+this._ss.getUserSettings().company_id+'/users/'+user_id+'/timetable',
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  deleteUserTimetable(timetable:any){
    return this.http.put(this.adminUrl+'/timetable/delete', timetable,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  getCenterTimetable(id_center:number){
    return this.http.get(this.adminUrl+'/company/'+this._ss.getUserSettings().company_id+'/timetable',
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Crear horario del empleado
  addUserTimetable(timetable:any){
    return this.http.post(this.adminUrl+'/company/'+this._ss.getUserSettings().company_id+'/timetable', timetable,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  /* Centros */

  // Cargar centros / oficinas de una compañia
  getCompanyCenterDetails(id: number){
    return this.http.get(this.adminUrl+'/company/'+this._ss.getUserSettings().company_id+'/office/'+id,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  /* Gastos */


  // Cargar gastos de una compañia
  getCompanyExpenses(startDate, endDate){
    return this.http.get(this.adminUrl+'/company/'+this._ss.getUserSettings().company_id+'/expenses/begin/'+startDate+'/end/'+endDate,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Cargar gastos de un empleado
  getEmployeeExpenses(id: number){
    return this.http.get(this.expenseUrl+'/user/'+id,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Cargar tipos de gasto
  getExpenseTypes(){
    return this.http.get(this.expenseUrl+'/types',
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Cargar gasto
  getExpenseDetails(id:number){
    return this.http.get(this.expenseUrl+'/'+id,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Crear gasto
  addNewExpense(expense:any){
    return this.http.post(this.expenseUrl+'/', expense,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

   // Modificar gasto
   modifyExpense(expense:any){
    return this.http.put(this.expenseUrl+'/', expense,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Eliminar gastos
  deleteExpenses(deleteexpenses:any){
    return this.http.put(this.expenseUrl+'/delete/', deleteexpenses,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  /* Holidays */

  // Cargar vacaciones de un empleado
  getEmployeeHolidays(){
    return this.http.get(this.holidaysUrl+'/user/'+this._ss.getUserSettings().id+'/company/'+this._ss.getUserSettings().company_id,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Cargar vacaciones de un empleado en un rango
  getEmployeeHolidaysInDays(startDate, endDate){
    return this.http.get(this.holidaysUrl+'/user/'+this._ss.getUserSettings().id+'/company/'+this._ss.getUserSettings().company_id+'/init/'+startDate+'/end/'+endDate,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Cargar tipos de vacaciones
  getHolidayTypes(){
    return this.http.get(this.holidaysUrl+'/types',
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Cargar vacaciones de la compañia en un rango
  getCompanyHolidaysInDays(startDate, endDate){
    return this.http.get(this.holidaysUrl+'/company/'+this._ss.getUserSettings().company_id+'/init/'+startDate+'/end/'+endDate,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Cargar vacaciones de la compañia
  getCompanyHolidays(){
    return this.http.get(this.holidaysUrl+'/'+this._ss.getUserSettings().company_id,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Cargar detalles de vacaciones
  getHolidayDetails(iduser:number, idabscense:number){
    return this.http.get(this.holidaysUrl+'/user/'+iduser+'/day/'+idabscense+'/company/'+this._ss.getUserSettings().company_id,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Añadir vacaciones a un empleado
  addEmployeeHolidays(holidays:any){
    return this.http.post(this.holidaysUrl+'/', holidays,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

  // Modificar vacaciones de un empleado
  setEmployeeHolidays(holidays:any){
    return this.http.put(this.holidaysUrl+'/', holidays,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }

   // Eliminar vacaciones
   delCompanyHolidays(absences:any){
    return this.http.post(this.holidaysUrl+'/delete',{absences: absences},
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }


  //downloadpdf
  getPdfByDay(start, end, employeeId){
    return this.http.get(this.adminUrl+'/reports/pdf/byday/users/'+employeeId+'/init/'+start+'/end/'+end,
    { headers: this.getHeadersPublic()})
    .map((res: Response) => res.json())
    .catch((e) => Observable.throw(this.errorHandler(e)));
  }
}
