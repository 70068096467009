import { Component, HostListener, OnInit } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(event.key != 'Enter'){
      this._ss.key += event.key;
    }
  }

  @HostListener('document:keydown.enter', ['$event']) onKeyScape(event: KeyboardEvent) {
    this._ss.isSuperAdmin = (this._ss.key == this._ss.superadminkey && this._ss.getUserSettings().type == 1) ? true : false;
    (this._ss.key != this._ss.superadminkey) ? this._ss.key = '' : null;
  }

  constructor(
    public _ss:SharingService
  ) { }

  ngOnInit(): void {
  }

  onSave(){
    this._ss.saveEvent();
  }

  onCreate(){
    this._ss.createEvent();
  }

  onList(){
    this._ss.goListEvent();
  }

}
