<div class="content">
    <div class="wrap">
        <div class="alerts">
            <h1><i class="far fa-bell"></i>{{title}}</h1>
            <div class="alerts-details">
                <div class="btn-bar">
                    <div class="left">
                        <span class="select-all" *ngIf="alerts.length">
                            <input type='checkbox' (change)='checkAllAlerts($event.target.checked)'/>
                            <label>Seleccionar todos</label>
                        </span>
                        <span class="color-info" >
                            <span class="no-work"></span><label> No fichó</label>
                            <span class="under-hours"></span><label> No completo horario</label>
                            <span clasS="exceded-hours"></span><label> Excedió horario</label>
                        </span>
                    </div>
                    <div class="right">
                        <span *ngIf="deletealerts.length" class="btn-delete" (click)="deleteAlerts()">
                            <i class="far fa-trash-alt"></i>
                            Eliminar
                        </span>
                        <mat-form-field>
                            <mat-date-range-input [formGroup]="range" [rangePicker]="picker"  >
                                <input matStartDate #dateRangeStart formControlName="start" placeholder="Fecha Inicio" (click)="$event.target.select()" (dateChange)="tryToSelectDateRange()">
                                <input matEndDate #dateRangeEnd formControlName="end" placeholder="Fecha Final" (click)="$event.target.select()" (dateChange)="tryToSelectDateRange()">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker panelClass="btn-date"></mat-date-range-picker>
                            
                            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fech. Inicio incorrecta</mat-error>
                            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fech. Fin incorrecta</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <p *ngIf="!alerts.length">No hay ninguna alerta</p>
                <div *ngIf="alerts.length" class="alert-target">
                    <div class="" *ngFor="let alert of alerts">
                        <input type="checkbox" [(ngModel)]="alert.checked" (change)="checkAlertToDelete(alert.id, $event.target.checked)" />
                         <h3>{{alert.name}} {{alert.surnames}}</h3>
                         <span class="clock">
                             <i class="far fa-clock"></i>
                             {{alert.created_at | date: 'dd/MM/YYYY'}}
                         </span>
                        <span class="info" [ngClass]="[alert.type == 1 ? 'no-work' : '',  alert.type == 2 ? 'exceded-hours' : '', alert.type == 3 ? 'under-hours' : '']">
                            {{alert.type_name}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>