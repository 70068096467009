import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { GoogleMapsAPIWrapper, AgmMap, MapsAPILoader} from '@agm/core';
import { SharingService } from 'src/app/services/sharing.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-centers',
  templateUrl: './list-centers.component.html',
  styleUrls: ['./list-centers.component.scss']
})
export class ListCentersComponent implements OnInit {

  
  centers = [];
  constructor(
    public mapsAPILoader:MapsAPILoader,
    public _ss:SharingService,
    private _router: Router,
    private _ap: ApiService
  ) { 
    _ss.setSectionTitle("Centros");
    _ss.saveButton = false;
    _ss.listButton = false;
    _ss.newButton = false;
  }

  ngOnInit(): void {
    this.getCenters();
  }

  getCenters(){
    this._ap.getCompanyCenters().subscribe((response)=>{
      if(response){
        this.centers = response.offices;
        console.log(this.centers);
      }else{

      }
    });
  }

  onClick(event){
    
  }

  showInfo(){

  }

  mapIdle() {
    console.log('idle');
  }

  goToCenter(id){
    this._router.navigateByUrl('/centros/'+id);
  }

}
