import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Http, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { SharingService } from './sharing.service';

@Injectable()

export class AuthenticationService {
    baseUrl = environment.apiURL;
    constructor(private http: Http,
        public _ss: SharingService) { }

    private getHeadersPublic() {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('api-key', 'abc123.');
        return headers;
    }

    private errorHandler(error: any): void {
        console.log('Se ha producido un error' + error);
      }

    login(username: string, password: string): Observable <any> {
    return this.http.put(this.baseUrl + '/login',
        {email: username, password: password},
        {headers: this.getHeadersPublic()} )
        .map(res => res.json())
        .catch((e) => Observable.throw(this.errorHandler(e)));
    }

    register(data: any): Observable <any> {
        return this.http.post(this.baseUrl + '/register', data, {headers: this.getHeadersPublic()} )
            .map(res => res.json()).catch((e) => Observable.throw(this.errorHandler(e)));
    }


    recoverPass(email: string): Observable <any> {
        return this.http.put(this.baseUrl + '/recoverpass',
            {email: email},
                {headers: this.getHeadersPublic()} )
                .map(res => res.json())
                .catch((e) => Observable.throw(this.errorHandler(e)));
        }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this._ss.setSuperAdmin(false);
    }

    handleError(error, errorFront, errorBody?) {
        console.log(error);
        Swal.fire(
          'Oops',
          'Se ha producido un error)',
          'error'
        )
      }
}
