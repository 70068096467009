import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  passwordForm: FormGroup;
  alert:any = {
    daily_alerts:false,
    weekly_alerts:false
  }

  notifications:any = {
    active: false,
    token:''
  }

  submitted = false;
  saveSettings;

  constructor(
    public _ss:SharingService,
    private _api:ApiService,
    private _ns:NotifierService,
    private formbuilder:FormBuilder
  ) {
    _ss.setSectionTitle("Ajustes");
    _ss.saveButton = true;
    _ss.listButton = false;
    _ss.newButton = false;
    this.passwordForm = this.formbuilder.group({
      password: ['', Validators.required],
      new_password:['', Validators.required],
      new_password_repeat:['',Validators.required]
    });

    this.saveSettings = this._ss.$save.subscribe(()=> {
        this.changePassword();
    });

  }

  get f() { return this.passwordForm.controls; }

  ngOnInit(): void {
    this.getAlertsConfig();
  }

  changePassword(){
    if(!this.passwordForm.invalid){
      if(this.passwordForm.controls['new_password'].value === this.passwordForm.controls['new_password_repeat'].value){
        this._api.changePass(this.passwordForm.value).subscribe((response)=>{
          if(response){
            this._ns.notify('success','Guardado correctamente!');
            this.passwordForm.reset();
          }else{
            this._ns.notify('error','Error: Al guardar los ajustes de usuario.');
          }
        },(err)=>{
          this._ss.errorHandler(err);
        });
      }else{
        this._ns.notify('error','Error: La nueva contraseña no coincide');
      }
    }else{
      this._ns.notify('error','Error: Algunos campos son obligatorios.');
    }
  }

  ngOnDestroy(){
    this.saveSettings.unsubscribe();
  }

  changeOptionStatus(alertType){
    if(alertType == 'daily'){
      this.alert.daily_alerts = !this.alert.daily_alerts;
    }else{
      this.alert.weekly_alerts = !this.alert.weekly_alerts;
    }
   this.modifyAlertsConfig();
  }

  changeNotificationStatus(status){
    this.notifications.active = status;
  }

  modifyAlertsConfig(){
    this._api.setCompanyAlertsConfig(this.alert).subscribe((response)=>{
      if(response.result == 'OK'){
        this._ns.notify('success','Configuración de alertas guardada correctamente!');
      }else{
        this._ns.notify('error','Error: al guardar la configuración de alertas.')
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  getAlertsConfig(){
    this._api.getCompanyAlertsConfig().subscribe((response)=>{
      if(response){
        this.alert = response.alerts;
        this.alert.daily_alerts = (this.alert.daily_alerts == 1) ?  true : false; 
        this.alert.weekly_alerts = (this.alert.weekly_alerts == 1) ? true : false;

        console.log(this.alert);
        console.log(this.alert); 
      }else{
        this._ns.notify('error','Error: Al cargar los ajustes.');
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

}
