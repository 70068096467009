import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SharingService } from 'src/app/services/sharing.service';
import swal from "sweetalert2";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [ style({opacity: 0}), animate(600) ]),
      transition(':leave', animate(600, style({opacity: 0}))) ])
  ]
})
export class LoginComponent implements OnInit {

  public email: string;
  public password: string;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  public switchOption: string;
  returnUrl: string;
  isRecoveryMode = false;

  constructor(
    private formBuilder: FormBuilder,
    private _auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private _ss:SharingService
  ) { 
    this._ss.padding = '0';
    this._ss.marginLeft = '0';
  }

  ngOnInit(): void {
    this.switchOption = 'login';

    if (this._ss.isLoged()) {
      this.router.navigateByUrl('/inicio');
    }

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // reset login status
    this._auth.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
  }

  submitForm(value: any) {
    this.submitted = true;
    this.email = value.username;
    this.password = value.password;
    this.login();
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  showModal(){
    this.isRecoveryMode = true;
  }

  login() {
    this.loading = true;
    this._auth.login(this.email, this.password).subscribe(
      response => {
        this.loading = false;
        if (response.result == 'OK') {
          this._ss.notify('success', 'Credenciales correctas');
          this._ss.setUserSettings(response.data);
          this.router.navigateByUrl('/jornada');
        } else {
          this._ss.notify('error', 'Usuario o contraseña incorrecta');
        }
      },
      error => {
        console.log(error);
        this.loading = false;
        this._ss.notify('error', error.msg || 'Error en login!');
      }
    );
  }


  recoverPass() {
    if(this.email){
    this._auth.recoverPass(this.email).subscribe((response)=>{
      if(response){
        this._ss.notify('success', 'Correo enviado correctamente!');
        this.isRecoveryMode = false;
      }else{
        this._ss.notify('error', 'Se ha producido un error');
      }
    }),((e)=>{
      this._ss.errorHandler(e);
    });
  }else{
    this._ss.notify('error','Debe introducir un correo electrónico');
  }
  }

}
