import { Location } from '@angular/common';
import { LEADING_TRIVIA_CHARS } from '@angular/compiler/src/render3/view/template';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';
import * as moment from 'moment';
import { createNoSubstitutionTemplateLiteral, isThisTypeNode } from 'typescript';
import { typeWithParameters } from '@angular/compiler/src/render3/util';

@Component({
  selector: 'app-add-holidays',
  templateUrl: './add-holidays.component.html',
  styleUrls: ['./add-holidays.component.scss']
})
export class AddHolidaysComponent implements OnInit {

  holidaysForm:FormGroup;
  //Array tipos de ausencias
  holidayTypes = [];
  // Empleados añadidos para vacaciones
  holidayEmployees = [];
  // Todos los empleados
  employees = []
  // Empleados filtrados
  employeesList = [];
  // String búsqueda
  employee;

  title = 'Nueva Ausencia';

  @Input() id_user;
  @Input() id_holidays;
  
  saveButton;
  goToList;
  isCollapse = false;
  isEdit = false;
  isFestive = false;

  isEmployee = false;

  constructor(
    private _api: ApiService,
    private formBuilder: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _location: Location,
    public _ss: SharingService,
    private _ns: NotifierService
  ) { 
    this.holidaysForm = this.formBuilder.group({
      id:[null],
      employees:[null],
      type_id:[null, Validators.required],
      date_start:[null, Validators.required],
      date_end:[null, Validators.required],
      observations:[null],
      fullday:[false],
      company_id:this._ss.getUserSettings().company_id
    });

    let params = this._activatedRoute.snapshot.params;
    (params.id) ? this.holidaysForm.controls.id.setValue(params.id) : null;
    this.id_user = (params.iduser) ? params.iduser : null;

    this._ss.saveButton = true;
    this._ss.listButton = true;
    this._ss.newButton = false;

    this.goToList = this._ss.$golist.subscribe(()=>{
      this._location.back();
    });

    this.saveButton = this._ss.$save.subscribe(()=> {
      this.saveHolidays();
    });

    this._ss.setSectionTitle('Nueva Ausencia');

    if(this._ss.getUserSettings().type == 0) {
      this.isEmployee = true;
      this._ss.saveButton = false;
      this._ss.setSectionTitle('Vacaciones');
    }
  }

  ngOnInit(): void {
    if(this.id_holidays != null) {
      this.holidaysForm.controls.id.setValue(this.id_holidays);
      this._ss.newButton = true;
    }
    this.getHolidayTypes();
    this.getEmployees();
  }

  get f(){return this.holidaysForm.controls};

  getHolidayTypes(){
    this._api.getHolidayTypes().subscribe((response)=>{
      if(response.result == 'OK'){
        this.holidayTypes = response.data;
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  getHolidayDetails(){
    console.log('Cargando detalles...');
    this._api.getHolidayDetails(this.id_user,this.holidaysForm.controls.id.value).subscribe((response)=>{
      if(response.result == 'OK'){
        this.holidaysForm.patchValue({
          id: response.data.id,
          type_id: response.data.id_absences_type,
          employees:null,
          date_start: moment(response.data.date_start).format('YYYY-MM-DD\THH:mm:ss'),
          date_end: moment(response.data.date_end).format('YYYY-MM-DD\THH:mm:ss'),
          fullday: (response.data.fullday) ? true : false,
          observations: response.data.observations
        });
        this.isEdit = true;
        console.log(this.employees);
        (response.data.id_user) ? this.holidayEmployees.push(this.employees.find( x => x.id == response.data.id_user)) : null;
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  getEmployees(){
    this._api.getCompanyEmployees().subscribe((response)=>{
      if(response){
        this.employees = response;
        this.employeesList = this.employees;
        if(this.holidaysForm.controls.id.value != null){
            this.title = 'Modificar Ausencia';
            this._ss.setSectionTitle(this.title);
          this.getHolidayDetails();
        }
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }))
  }

  getEmployeesId($event){
    this.employeesList = this.employees;
    let name = $event.target.value;
    let list = (name.length > 0) ? this.employeesList.filter(x => x.name == name)[0] : null;
    console.log(list);
    if(!(this.holidayEmployees.filter(x => x.id == list.id).length > 0) ){
      this.holidayEmployees.push(this.employees.find(x => x.id == list.id));
    }
    this.employee = '';
    this.holidaysForm.controls.employees.setValue(null);
  }

  deleteEmployee(id){
    this.holidayEmployees = this.holidayEmployees.filter(x => x.id != id);
  }

  saveHolidays(){
    this.holidaysForm.controls.employees.setValue(this.holidayEmployees);
    if(!this.holidaysForm.invalid){
      if((this.holidayEmployees.length && this.holidaysForm.controls.type_id.value != 2) || (!this.holidayEmployees.length && this.holidaysForm.controls.type_id.value == 2)){
        (this.holidaysForm.controls.id.value != null) ? this.setEmployeeHolidays() : this.addEmployeeHolidays();
      }else{
        this._ns.notify('error','Error: Debe selecciona al menos un empleado.');
      }
    } else{
      this._ns.notify('error','Error: algunos campos son obligatorios.');
    }   
  }

  setEmployeeHolidays(){
    this._api.setEmployeeHolidays(this.holidaysForm.value).subscribe((response)=>{
      if(response.result == 'OK'){
        this._ns.notify('success','Guardado correctamente!');
      }else{
        this._ns.notify('error','Error: en el guardado!');
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  addEmployeeHolidays(){
    this._api.addEmployeeHolidays(this.holidaysForm.value).subscribe((response)=>{
      if(response.result == 'OK'){
        this._ns.notify('success','Ausencia creada correctamente!');
        this.holidaysForm.reset();
        this.holidayEmployees = [];
        this.holidaysForm.patchValue({
          id:null,
          employees:null,
          date_start:null,
          date_end:null,
          fullday:false,
          observations:null
        });
        this.isFestive = false;
      }else{
        this._ns.notify('error','Error: al crear la ausencia!');
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  changeAbsenceType(id){
    console.log(this.holidayTypes);
    if(this.holidayTypes.find(x => x.id == id).description == 'Festivo'){
      console.log('entre en festivo');
      this.isFestive = true;
      this.holidayEmployees =  [];
      this.holidaysForm.controls.employees.setValue(null);
    }else{
      this.isFestive = false;
    }
  }

  ngOnDestroy(){
    this.saveButton.unsubscribe();
    this.goToList.unsubscribe();
  }

}
