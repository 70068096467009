import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';
import * as moment from 'moment';

@Component({
  selector: 'app-journal-summary-details-edit',
  templateUrl: './journal-summary-details-edit.component.html',
  styleUrls: ['./journal-summary-details-edit.component.scss']
})
export class JournalSummaryDetailsEditComponent implements OnInit {

  options = [];
  @Input() journal;
  @Output() onModifyJournal = new EventEmitter<any>();

  journalForm:FormGroup;

  title:String= 'Editando jornada:';
  

  constructor(
    private formBuilder: FormBuilder,
    private _api:ApiService,
    private _ss:SharingService
  ) {
    this.journalForm = this.formBuilder.group({
      id:[null],
      user_id:[null],
      longitude_in:[0],
      latitude_in:[0],
      longitude_out:[0],
      latitude_out:[0],
      checkin:['', Validators.required],
      checkout:['', Validators.required],
      office_in:[null],
      office_out:[null],
      option_in:[null, Validators.required],
      option_out: ['', Validators.required]
    });
   }

  ngOnInit(): void {
    //(this.journal) ? this.journalForm = this.journal : null;
    console.log(this.journal);
    this.journalForm.controls.id.setValue(this.journal.id);
    this.journalForm.controls.user_id.setValue(this.journal.user_id);
    this.journalForm.controls.longitude_in.setValue(this.journal.longitude_in);
    this.journalForm.controls.latitude_in.setValue(this.journal.latitude_in);
    this.journalForm.controls.longitude_out.setValue(this.journal.longitude_out);
    this.journalForm.controls.latitude_out.setValue(this.journal.latitude_out);
    this.journalForm.controls.option_in.setValue(this.journal.option_in);
    this.journalForm.controls.option_out.setValue(this.journal.option_out);
    if(this.journal.office_in != null) { this.journalForm.controls.option_in.setValue(this.journal.office_in) };
    if(this.journal.office_out != null) { this.journalForm.controls.option_out.setValue(this.journal.office_out) };
    this.journalForm.controls.checkin.setValue(moment(this.journal.checkin).format('YYYY-MM-DD\THH:mm:ss'));
    this.journalForm.controls.checkout.setValue(moment(this.journal.checkout).format('YYYY-MM-DD\THH:mm:ss'));
    console.log(this.journalForm.value);
    this.getJournalOptions();
  }

  get f() { return this.journalForm.controls; }

  getJournalOptions(){
    this._api.getJournalActiveOptions(this.journalForm.controls.user_id.value).subscribe((response)=>{
      if(response){
        this.options = response.options;
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  modifyJournal(){
    console.log(this.options);
    console.log(this.journalForm.value);
  
    console.log(this.journalForm.value);
    this._api.setJournalEmployee(this.journalForm.value).subscribe((response)=>{
      if(response){
        this.closeJournalEditor();
      }else{

      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
    
  }

  isAnOption(select, option){
    let type = option.options[option.selectedIndex].getAttribute('data-type');
    if(type == 0){
      if(select == 'checkin') {
        this.journalForm.controls.office_in.setValue(option.value);
        this.journalForm.controls.option_in.setValue(null);
      }else{
        this.journalForm.controls.office_out.setValue(option.value);
        this.journalForm.controls.option_out.setValue(null);
      }
      
    }else{
      if(select == 'checkin') {
        this.journalForm.controls.option_in.setValue(option.value);
        this.journalForm.controls.office_in.setValue(null);
      }else{
        this.journalForm.controls.option_out.setValue(option.value);
        this.journalForm.controls.office_out.setValue(null);
      }
    }
  }


  closeJournalEditor(){
    this.onModifyJournal.emit();
  }
}


