<div class="content">
    <div class="wrap">
        <div class="expense-content" [ngClass]="{'collapse' : isCollapse }">
            <div class="title">
                <h1><i class="far fa-credit-card"></i>{{title}}</h1>
                <span class="btn-close" (click)="showDetails()">
                    <i class="fas fa-chevron-down"></i>
                </span>
            </div>
            <form [formGroup]="expenseForm" class="expense-details">
                <div class="row space-between col-1">
                    <div class="column">
                        <label for="description">Descripción*</label>
                        <input name="description" type="text" formControlName="description" #description  placeholder="Descripción" [ngClass]="{ 'is-invalid': submitted && f.description.errors }" required />
                    </div>
                   
                </div>
                <div class="row space-between col-3">
                    <div class="column">
                        <label for="type">Tipo*</label>
                        <select name="type" formControlName="type" #type [ngClass]="{ 'is-invalid': submitted && f.type.errors }" >
                            <option [value]="f.type.value" [selected]="null == f.type.value" disabled>Selecciona un tipo</option>
                            <option *ngFor="let type of expenseTypes" [value]="type.id" [selected]="type.id == f.type.value">
                                {{type.name}}
                            </option>
                        </select>
                    </div>
                    <div class="column">
                        <label for="date">Fecha*</label>
                        <input name="date" type="datetime-local" formControlName="date" #date [ngClass]="{ 'is-invalid': submitted && f.date.errors }" required />
                    </div>
                    <div class="column">
                        <label for="amount">Cantidad*</label>
                        <input name="amount" type="number" formControlName="amount" #amount placeholder="Cantidad" [ngClass]="{ 'is-invalid': submitted && f.amount.errors }" required />
                    </div>
                </div>
                <div class="row">
                    <input *ngIf="!isEdit" type="file" ng2FileSelect [uploader]="uploader" (onFileSelected)="onFileSelected($event)" />
                                        
                    <div class="btn-expenses" *ngIf="f.filename.value">
                        <a class="btn" href="{{_ss.imageUrl}}{{f.filename.value}}" target="_blank"><i class="far fa-save"></i> descargar</a>
                        <a class="btn" (click)="showTicket(true)"><i class="far fa-eye"></i> visualizar</a>
                    </div>

                    <div ng2FileDrop *ngIf="!isEdit"
                        [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                        (fileOver)="fileOverBase($event)"
                        [uploader]="uploader"
                        class="well my-drop-zone">
                    Base drop zone
                    </div>

                    <div class="queue-bar" *ngIf="!isEdit">
                        <h3>Cola de Archivos</h3>
                        <p>Archivos subidos: {{ uploader?.queue?.length }}</p>
                    </div>
                   
                    <div *ngIf="false">
                        Queue progress:
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal image -->

<div class="modal" *ngIf="isTicketDisplayed">
    <div class="modal-content">
        <span class="close" (click)="showTicket(false)"><i class="fas fa-times"></i></span>
        <div  class="image" [ngStyle]="">
            <img src="{{_ss.imageUrl}}{{f.filename.value}}" />
        </div>
    </div>
</div>
