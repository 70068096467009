import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { ApiService } from 'src/app/services/api.service';
import { Observable, interval } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-journal',
  templateUrl: './journal.component.html',
  styleUrls: ['./journal.component.scss'],
  animations:[
    trigger('simpleFadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [ style({opacity: 0}), animate(600) ]),
      transition(':leave', animate(600, style({opacity: 0}))) ])
  ]
})
export class JournalComponent implements OnInit {


  public checktitle = 'Chequear Entrada';
  public actualDay;

  CheckTimes = [];
  userStatus:Boolean = true;
  counter:any = { hours: 0, minutes: 0, active: false };
  idJournal;
  sub;
  coords = { latitude: 0, longitude: 0 };
  loadingcoords;
  gpsOptions = { timeout: 5000, enableHighAccuracy: true };
  journal:any = [];

  showCheckOptions = false;
  options = [];

  public counter_obs: any;

  constructor(
    public _ss: SharingService,
    private _api :ApiService,
    private _ns:NotifierService
  ) {
    _ss.setSectionTitle("Mi jornada");
    _ss.saveButton = false;
    _ss.listButton = false;
    _ss.newButton = false;
    moment.locale("es");
    this.actualDay = moment().format('D MMMM YYYY');
   }

  ngOnInit(): void {
    this.getUserCheckIn();
  }

  onClick(event){

  }

  getUserCheckIn() {
    console.log('this.getUserCheckIn();');
    this.counter.hours = 0;
    this.counter.minutes = 0;
    
    let date = moment().format('YYYY-MM-DD');
    this._api.getUserCheckInDay(this._ss.getUserSettings().id, date).subscribe((response) => {
      if (response.success === 'OK') {
        // console.log(response);
        this.CheckTimes = response.data;
        let longitud = this.CheckTimes.length;
        
        if(longitud){
          this.CheckTimes.forEach(checktime => {
            checktime.op_in = (checktime.origin == 4 && checktime.checkin) ? ' Hardware Biométrico (HB)' : checktime.op_in;
            checktime.op_out = (checktime.origin == 4 && checktime.checkout) ? ' Hardware Biométrico (HB)' : checktime.op_out;
          });
        }
        
        if (this.CheckTimes.length != 0) {
          if (this.CheckTimes[longitud - 1].checkout != null) {
            this.userStatus = true;
            this.checktitle = 'CHEQUEAR ENTRADA';
          } else {
            this.userStatus = false;
            this.counter.active = true;
            this.idJournal = this.CheckTimes[longitud - 1].id;
            this.checktitle = 'CHEQUEAR SALIDA';
          }
        }
        this.CountUp();
      } else {
        this._ns.notify("error","Se ha producido un error. Inténtelo de nuevo");
      }
    }, (err) => {
      this._ss.errorHandler(err);
    });
  }



  CountUp() {
    this.counter;
    if (this.userStatus == true && this.CheckTimes.length != 0) {
      this.CheckTimes.forEach(element => {
        let date = element.checkout;
        let lastdate = element.checkin;
        let counter = moment(date).diff(moment(lastdate));
        let time = moment.utc(counter).format('H[,]mm');
        let arraytime = time.split(',');
        let hours = Number(arraytime[0]);
        let minutes = Number(arraytime[1]);
        this.counter.hours += Number(hours);
        this.counter.minutes += Number(minutes);
      });
    } else {
      this.CheckTimes.forEach(element => {
        let date = moment();
        if (element.checkout != null) {
          date = element.checkout;
        }
        let lastdate = element.checkin;
        let counter = moment(date).diff(moment(lastdate));
        let time = moment.utc(counter).format('H[,]mm');
        let arraytime = time.split(',');
        let hours = Number(arraytime[0]);
        let minutes = Number(arraytime[1]);
        this.counter.hours += Number(hours);
        this.counter.minutes += Number(minutes);
      });
      this.ClockRuning();
    }
    if (this.counter.minutes > 59) {
      this.counter.minutes = this.counter.minutes - 60;
      this.counter.hours++;
    }
  }

  // Reloj

  ClockRuning() {
    if(this.counter.active) {
      console.log(this.sub);
      
      if (this.counter_obs) {
        this.counter_obs.unsubscribe();
      }
    
      this.counter_obs = interval(60000)
        .subscribe((val) => {
          if (this.counter.minutes > 59) {
            this.counter.hours += 1;
            this.counter.minutes = 0;
          }else{
            this.counter.minutes += 1;
          }
        });
        // console.log('reloj en movimiento...');
    } else {
      if (this.counter_obs != null) {
        this.counter_obs.unsubscribe();
      }
      // console.log('reloj en parado...');
    }
  }

  //type -> 0 checkin, 1 -> checkout
  saveJournalOption(type, journal_id, option) {
    console.log("Actualizando opción");
    this.checktitle = (type != 1) ?  'CHEQUEAR ENTRADA' : 'CHEQUEAR SALIDA';
    let request = {type:type, journal_id: journal_id, option:option};
    console.log(option);
    this._api.putJournalOption(request).subscribe((response) => { 
      if(response.success=='OK'){
        console.log(response);    
        this.getUserCheckIn();
        this._ns.notify('success','Fichaje realizado correctamente!');
      }
    }, ((err) => {
      console.log('Se ha producido un error en el checkin' + err);
      this._ss.errorHandler(err);
    }));
  }

  showOfficeOptions(type, journal_id, options) {
    console.log(type, journal_id, options);
    this.options = options;
    this.showCheckOptions = true;
    this.CheckTimes[this.CheckTimes.length - 1].id = journal_id;
    this.CheckTimes[this.CheckTimes.length - 1].type = type;
    /*
    var selectOptions = [];
    options.forEach(option => {
      let optString = '{'+option.id+':'+option.name+'}';
      console.log(optString);
      let opt =  JSON.parse(optString);
      selectOptions.push(opt);
    });

    Swal.fire({
      title: 'No se puedo encontrar la ubicación',
      text: "indica desde dónde estás fichando",
      icon: 'warning',
      input:'select',
      inputOptions: selectOptions,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.saveJournalOption(type, journal_id, result.value);
      }
    })
    */
 
  }

  saveOfficeOption(option){
    console.log(option);
    console.log(this.CheckTimes);
    let lastchecktime = this.CheckTimes[this.CheckTimes.length - 1];
    lastchecktime.type = (lastchecktime.checkout != null) ? 1 : 0;
    console.log(lastchecktime);
    this.saveJournalOption(lastchecktime.type, lastchecktime.id, option);
    this.showCheckOptions = false;
  }

  checkIn() {
    if(navigator.geolocation){
      console.log("entro aqui");
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        this.coords.latitude = position.coords.latitude;
        this.coords.longitude = position.coords.longitude;
        console.log('las coordenadas son:' + this.coords.latitude + ' ' + this.coords.longitude);
        console.log(this._ss.getUserSettings().id);
        this.doCheck();
      },(err) => {
        this.doCheck();
        console.log('Error getting location', err);
        if(err.code == 1){
          this._ns.notify('warning','Fichaje realizado sin control de ubicaciones');
        }else{
          this._ss.errorHandler(err);
        }
      });
    }else{
      this._ns.notify('error','La geolocalización no es soportada por este navegador');
    }
  }


  doCheck() {
    if (this.userStatus == true) {
      this._api.checkInTime(this._ss.getUserSettings().id, this.coords).subscribe((response) => {
        if (response.success == 'OK') {
          this.CheckTimes.push({ id: response.data.id, checkin: moment(), id_user: this._ss.getUserSettings().id, checkout: null });
          console.log(this.CheckTimes);
          this.getUserCheckIn();
          this.idJournal = response.data.id;
          this.userStatus = false;
          if (response.data.office == null) {
            this.showOfficeOptions(0, response.data.id, response.data.options);
          }
          else {
            this._ns.notify("success","Fichaje realizado en "+response.data.office_name);
            this.getUserCheckIn();
          }
        } else {
          this._ns.notify("error","Se ha producido un error. Inténtelo de nuevo");
        }
      }, (err) => {
          this._ss.errorHandler(err);
      });
    } else {
      this._api.checkOutTime(this.idJournal, this._ss.getUserSettings().id, this.coords).subscribe((response) => {
        if (response.success === 'OK') {
          // console.log(response);
          // console.log(response.data.id);
          let index = this.CheckTimes.findIndex(x => x.id == response.data.id);
          // console.log(index);
          // console.log(this.CheckTimes);
          this.CheckTimes[index].checkout = moment().format('YYYY-MM-DD HH:mm:ss');
          // console.log(this.CheckTimes);
          this.userStatus = true;
          this.counter.active = false;
          this.ClockRuning();
          if (response.data.office == null) {
            this.showOfficeOptions(1, response.data.id, response.data.options);
          }
          else {
            this._ns.notify("success","Fichaje realizado en "+response.data.office_name);
            this.getUserCheckIn();
          }
        } else {
          this._ns.notify("error","Se ha producido un error. Inténtelo de nuevo");
        }
      }, (err) => {
        this._ss.errorHandler(err);
      });
    }
  }

  formatHour(date){
    if (date){
      return(moment(date).format('HH:mm'));
    }
    else return('');
  }

  isNotFromToday(time) {
    return time && !moment(time).isSame(moment(), 'day');
  }

  getDay(time) {
    return moment(time).format('DD MMM');
  }

  getPlaceIn(check) {
    return check.of_in ? check.of_in : check.op_in;
  }

  getPlaceOut(check) {
    return check.of_out ? check.of_out : check.op_out;
  }

}
