<div class="content">
    <div class="wrap">
        <div class="expense-content">
            <h1><i class="far fa-credit-card"></i>Gastos: {{user.name}} {{user.surnames}} <span *ngIf="user.nif">( {{user.nif}} )</span> </h1>
            <div class="expense-details">
                <div class="top-bar">
                    <span class="select-all" *ngIf="expenses.length">
                        <input type='checkbox' (change)='checkAllExpenses($event.target.checked)'/>
                        <label>Seleccionar todos</label>
                    </span>
                    <ul clasS="info-bar">
                        <li *ngFor="let type of types"><span class="circle" [ngStyle]="{'background-color': getTypesColor(type.id)}" ></span>{{type.name}}</li>
                    </ul>
                    <span *ngIf="deleteexpenses.length" class="btn-delete" (click)="delExpenses()">
                        <i class="far fa-trash-alt"></i>
                        Eliminar
                    </span>
                    <div class="total-expense"><span>Total:</span> {{totalExpenses | number : '1.2-2'}} €</div>    
                </div>
                <p class="alert" *ngIf="!expenses.length">Actualmente no dispone de gastos!</p>
                <div class="expense" *ngFor="let expense of expenses">
                    <input type="checkbox" [(ngModel)]="expense.checked" (change)="checkExpenseToDelete(expense.id, $event.target.checked)" />
                    <h1>{{expense.description}}</h1> <span class="time"><i class="far fa-clock"></i> {{expense.date | date: 'dd/MM/YYY'}}</span> 
                    <span class="price">{{expense.amount}} €</span>
                    <span class="expense-type" [ngStyle]="{'background-color': getTypesColor(expense.type)}"><i [ngClass]="getTypeIcon(expense.type)"></i>{{expense.type_name}}</span>
                    <a class="btn btn-edit" (click)="setExpense(expense.id)"><i class="far fa-edit"></i> Editar</a>
                </div>
            </div>
        </div>
    </div>
</div>