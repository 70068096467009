import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';
import { expenseTypes } from 'src/app/constants';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-expense-details',
  templateUrl: './expense-details.component.html',
  styleUrls: ['./expense-details.component.scss']
})
export class ExpenseDetailsComponent implements OnInit {

  expenses = [];
  deleteexpenses = [];
  types = [];
  typeStyles:any = expenseTypes;
  iduser;
  user:any = {};
  totalExpenses = 0;

  newButton;
  goToList;


  constructor(
    private _api:ApiService,
    public _ss:SharingService,
    private _ns: NotifierService,
    private location: Location,
    private router: Router,
    private _activatedRoute: ActivatedRoute
  ) { 
    let params = this._activatedRoute.snapshot.params;
    this.iduser = (params.id) ? params.id : this._ss.getUserSettings().id;

    this._ss.newButton = true;
    this._ss.saveButton = false;
    this._ss.listButton = (params.id) ? true : false;

    this.goToList = this._ss.$golist.subscribe(()=>{
      this.location.back();
    });

    this.newButton = this._ss.$create.subscribe(() => {
      this.router.navigateByUrl('/gastos/nuevo');
    });

    this._ss.setSectionTitle('Gastos Empleado');
  }

  ngOnInit(): void {
    this.getExpenseTypes();
    this.getUserExpenses();
  }

  getExpenseTypes(){
    this._api.getExpenseTypes().subscribe((response)=>{
      if(response.result == 'OK'){
        this.types = response.data;
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  getTypesColor(id){
   return this.typeStyles.find(x => x.id == id).color;
  }

  getTypeIcon(id){
    return this.typeStyles.find(x => x.id == id).icon;
  }

  getUserExpenses(){
    this._api.getEmployeeExpenses(this.iduser).subscribe((response)=>{
      if(response.result == 'OK'){
        this.expenses = response.data;
        this.user = {name: response.data[0].name, surnames: response.data[0].surnames, nif: response.data[0].nif};
        this.expenses.forEach(elt => {
          elt.checked = false;
          this.totalExpenses += elt.amount;
        });
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  ngOnDestroy(){
    this.goToList.unsubscribe();
    this.newButton.unsubscribe();
  }

  setExpense(id){
   console.log(id);
   this.router.navigateByUrl('/gastos/'+id+'/editar');
  }

  checkAllExpenses(checked){
    this.expenses.forEach(exp => {
      exp.checked = checked;
    });

    if(checked){
      this.deleteexpenses = this.expenses.filter(x => x.checked == checked);
    }else{
      this.deleteexpenses = [];
    }
  }

  checkExpenseToDelete(expense, status){
    this.expenses.find( x => x.id === expense).checked = status;

    if(status){
      this.deleteexpenses.push({id: expense});
    }else{
      this.deleteexpenses = this.deleteexpenses.filter(x => x.id != expense);
    }
    
  }

  delExpenses(){
    this._api.deleteExpenses(this.deleteexpenses).subscribe((response)=>{
      if(response){
        this._ns.notify('success','Eliminado correctamente');
        this.ngOnInit();
      }else{
        this._ns.notify('error','Error: al eliminar los gastos.');
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

}
