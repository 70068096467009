<div class="content">
    <div class="wrap">
        <div class="bar-buttons">
            <div class="left">
                <span><i class="far fa-calendar-alt"></i>{{actualDay}}</span>
            </div>
            <div class="center">
                <h1 class="counter">{{counter.hours}} <span>h</span> {{counter.minutes}} <span>min</span></h1>
            </div>
            <div class="right">
                <a (click)="checkIn()"><i class="far fa-clock"></i>{{checktitle}}</a>
            </div>
        </div>
        <ngx-datatable
        class="material"
        [rows]="CheckTimes"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        rowHeight="auto"
        [sorts]="[{prop: 'created_at', dir: 'desc'}]"
        [limit]="10"
        [headerHeight]="50"
        [footerHeight]="50"
        [@simpleFadeAnimation]="'in'"
        [selectionType]="'checkbox'"
        [selectAllRowsOnPage]="false"
        (activate)='onClick($event)'
      >
    <ngx-datatable-column prop="checkin" name="ENTRADA">
        <ng-template let-value="value" ngx-datatable-cell-template>
            {{value | date:'HH:mm'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="LUGAR">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.op_in != null" [innerHTML]="row['op_in']"></span>
            <span *ngIf="row.of_in != null" [innerHTML]="row['of_in']"></span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="checkout" name="SALIDA">
        <ng-template let-value="value" ngx-datatable-cell-template>
            {{value | date:'HH:mm'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="LUGAR">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.op_out != null" [innerHTML]="row['op_out']"></span>
            <span *ngIf="row.of_out != null" [innerHTML]="row['of_out']"></span>
        </ng-template>
    </ngx-datatable-column>
    </ngx-datatable>
    </div>
</div>

<app-journal-options *ngIf="showCheckOptions" [options]="options" (onSaveOption)="saveOfficeOption($event)"></app-journal-options>