<ul class="menu-left" *ngIf="_ss.isLoged()" [ngClass]="{'opened' : _ss.isOpen }">
    <li class="user-profile"><a><div class="avatar"></div><span>{{username}}</span></a></li>
    <li>
        <a (click)="openMenu()">
            <i class="fas fa-bars"></i>
            <span>Menu</span>
        </a>
    </li>
    <li *ngFor="let link of menulinks">
        <a [routerLink]="[link.href]"  [routerLinkActive]="['active']" *ngIf="link.active">
            <i class="{{link.icon}}"></i>
            <span>{{ link.title }}</span>
        </a>
    </li>
    <li>
        <a (click)="_ss.logout()">
            <i class="fas fa-power-off"></i>
            <span>Salir</span>
        </a>
    </li>
</ul>



