<div class="content">
    <div class="wrap">
        <form class="password" [formGroup]="passwordForm">
            <h2 class="title">Contraseña</h2>
            <div class="details">
                <div class="row col-3 space-between">
                    <div class="column">
                        <label for="name">Contraseña actual</label>
                        <input type="password" formControlName="password" #password name="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" required>
                    </div>
                    <div class="column">
                        <label for="new_password">Nueva contraseña</label>
                        <input type="password" formControlName="new_password" #new_password name="new_password" [ngClass]="{ 'is-invalid': submitted && f.new_password.errors }" required>
                    </div>
                    <div class="column">
                        <label for="new_passsword_repeat">Confirma nueva contraseña</label>
                        <input type="password" formControlName="new_password_repeat" #new_password_repeat name="new_password_repeat" [ngClass]="{ 'is-invalid': submitted && f.new_passsword_repeat.errors }" required>
                    </div>
                </div>
            </div>
        </form>
        <div class="row col-1 space-between">
            <div class="alerts column" *ngIf="_ss.getUserSettings().type == 1">
                <h2>Configurar alertas</h2>
                <div class="details">
                 <div class="row space-between col-2">
                     <div class="column">
                         <label for="daily-alert">Alertas diarias*</label>
                         <ui-switch [checked]="alert.daily_alerts" (change)="changeOptionStatus('daily')"></ui-switch>
                     </div>
                     <div class="column">
                         <label for="daily-weekly">Alertas semanales**</label>
                         <ui-switch [checked]="alert.weekly_alerts" (change)="changeOptionStatus('weekly')"></ui-switch>
                     </div>
                 </div>
                </div>
                <div class="info">
                 <p>*Las alertas diarias se enviarán a las <b>7:30h</b> indicando los trabajadores que no hayan fichado el día anterior o que hayan computado horas de más o horas de menos según el horario establecido.</p>
                 <p>**Las alertas semanales se enviarán los <b>lunes a las 7:30h</b> indicando los trabajadores que hayan computado la semana anterior horas de más o horas de menos según el horario establecido.</p>
                </div>
            </div>

            <!--
            <div class="alerts column">
                <h2>Configurar Notificaciones</h2>
                <div class="details">
                    <div class="row space-between col-2">
                        <div class="column">
                            <label for="daily-alert">Notificiones*</label>
                            <ui-switch [checked]="notifications.active" (change)="changeNotificationStatus($event)"></ui-switch>
                        </div>
                    </div>
                </div>
                <div class="info">
                    <p>*Las notificaciones diarias se enviarán a los empleados que no hayan fichado <b>10 minutos</b> después del inicio de su jornada laboral.</p>
                </div>
            </div>-->
        </div>

    </div>
</div>
