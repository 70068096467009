import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [ style({opacity: 0}), animate(600) ]),
      transition(':leave', animate(600, style({opacity: 0}))) ])
  ]
})
export class ListUsersComponent implements OnInit {

  public loading;
  public employees;
  newEmployee; 

  constructor(
    public _ss:SharingService,
    private _api: ApiService,
    private _ns: NotifierService,
    private router: Router
  ) {
    _ss.setSectionTitle("Empleados");
    _ss.newButton = true;
    _ss.listButton = false;
    _ss.saveButton = false;
   }

  ngOnInit(): void {
    this.getEmployees();
    this.newEmployee = this._ss.$create.subscribe(() => {
      this.router.navigateByUrl("/empleado/nuevo");
    });
  }

  getEmployees(){
    this._api.getCompanyEmployees().subscribe((response)=>{
      if(response){
        this.employees = response;
        this.employees.forEach(employee => {
          employee.status = (employee.status == 1) ? true : false;
          employee.alerts = (employee.alerts == 1) ? true : false;
        });
        console.log(this.employees);
      }else{
        console.log("Se ha producido un error");
      }
    });
  }

  onClick(event){
    if (event.type === 'dblclick') {
      this.router.navigateByUrl('/empleado/'+event.row.id+'/jornada');
    }
  }

  onEdit(row){
      this.router.navigateByUrl('/empleado/'+row.id+'/editar');
  }

  changeUserActiveAlert(user, status){
    this.employees.find(x => x.id == user.id).alerts = status;
    console.log(user);
    this.modifyEmployee(user);
  }

  changeUserActiveStatus(user, status){
    this.employees.find(x => x.id == user.id).status = status;
    console.log(user);
    this.modifyEmployee(user);
  }

  modifyEmployee(user){
    this._api.setCompanyEmployee(user).subscribe((response)=>{
      if(response){
        this._ns.notify('success','Estado cambiado correctamente');
      }else{
        this._ns.notify('Error','Al cambiar el estado.');
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  ngOnDestroy(){
    this.newEmployee.unsubscribe();
  }

}
