<div class="content">
    <div class="wrap">
        <div class="button-bar">
            <div class="left">
                <span><i class="far fa-calendar-alt"></i>{{checkDay}}</span>
            </div>
            <div class="center">
                <h1 class="counter">{{counter.hours}} <span>h</span> {{counter.minutes}} <span>min</span></h1>
            </div>
            <div class="right">

            </div>
        </div>
        
        <ngx-datatable
        class="material"
        [rows]="checksInDay.checks"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        rowHeight="auto"
        [sorts]="[{prop: 'created_at', dir: 'desc'}]"
        [limit]="10"
        [headerHeight]="50"
        [footerHeight]="50"
        [@simpleFadeAnimation]="'in'"
        [selectionType]="'checkbox'"
        [selectAllRowsOnPage]="false"
      >
    <ngx-datatable-column prop="checkin" name="ENTRADA">
        <ng-template let-value="value" ngx-datatable-cell-template>
            {{value | date:'HH:mm'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="LUGAR">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.op_in != null" [innerHTML]="row['op_in']"></span>
            <span *ngIf="row.of_in != null" [innerHTML]="row['of_in']"></span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="checkout" name="SALIDA">
        <ng-template let-value="value" ngx-datatable-cell-template>
            {{value | date:'HH:mm'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="LUGAR">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.op_out != null" [innerHTML]="row['op_out']"></span>
            <span *ngIf="row.of_out != null" [innerHTML]="row['of_out']"></span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="_ss.isSuperAdmin">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span class="btn btn-edit" (click)="modifyJournalUser(row)"><i class="fas fa-pencil-alt"></i> Editar</span>
        </ng-template>
    </ngx-datatable-column>
    </ngx-datatable>
    </div>
</div>

<app-journal-summary-details-edit *ngIf="journalIsEdit" [journal]="journal" (onModifyJournal)="onModifyJournal()"></app-journal-summary-details-edit>