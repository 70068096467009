import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';


@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss']
})
export class TimetableComponent implements OnInit {

@Input() iduser;
@Input() idcenter;

showAddTimeTable = true;

  timetable = [
    {
     week_day:1,
     name:'Lunes',
     hours:[]
    },{
      week_day:2,
      name:'Martes',
      hours:[]
    },{
      week_day:3,
      name:'Miércoles',
      hours:[]
    },{
      week_day:4,
      name:'Jueves',
      hours:[]
    },{
      week_day:5,
      name:'Viernes',
      hours:[]
    },{
      week_day:6,
      name:'Sábado',
      hours:[]
    },{
      week_day:7,
      name:'Domingo',
      hours:[]
    }
  ];

  deletetimetable = {
    user_id:null,
    id:null
  };

  constructor(
    private _api:ApiService,
    public _ss:SharingService
  ) {
   
   }

  ngOnInit(): void {
    console.log(this.iduser);
    console.log(this.idcenter);
    if(this.iduser) {
      this.getUserTimetable() 
      this.deletetimetable.user_id = this.iduser
    }
    else{
      this.getCenterTimetable();
    } 
  }

  getUserTimetable(){
    this._api.getUserTimetable(this.iduser).subscribe((response)=>{
      console.log(response);
      this.timetable.forEach(day => {
       day.hours = response.timetable.filter( x => x.week_day == day.week_day);
      });
      console.log(this.timetable);
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  getCenterTimetable(){
    this._api.getCenterTimetable(this.idcenter).subscribe((response)=>{
      console.log(response);
      this.timetable.forEach(day => {
       day.hours = response.timetable.filter( x => x.week_day == day.week_day);
      });
      console.log(this.timetable);
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }


  addTimeTable(isNew, day, hour){
    console.log(isNew, day, hour);
    this.showAddTimeTable = !this.showAddTimeTable;
    if(!isNew){
      (this.iduser) ? this.getUserTimetable() : this.getCenterTimetable();
    }else{
      (this.iduser) ? this.getUserTimetable() : this.getCenterTimetable();
    }
  }

  removeHour(day, id){
    this.deletetimetable.id = id;
    console.log("Eliminando", day, id);
    this._api.deleteUserTimetable(this.deletetimetable).subscribe((response)=>{
      if(response){
        this._ss.notify("success","Horario eliminado correctamente!");
        this.timetable.find(x => x.week_day == day).hours = this.timetable.find(x => x.week_day == day).hours.filter(x => x.id != id);
      }else{
        this._ss.notify("error","Error: No se ha podido eliminar el horario");
      }
    },((e)=>{
      this._ss.errorHandler(e);
    }));
  }
}
