import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';
import * as moment from 'moment';
import { absencesTypes } from 'src/app/constants';
import { _isNumberValue } from '@angular/cdk/coercion';
import { NotifierService } from 'angular-notifier';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class HolidaysComponent implements OnInit {

  addButton;
  absences = [];
  deleteabsences = [];
  absencesTypes = absencesTypes;

  isCalendar = true;
  newHolidays = false;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  constructor(
    private _api: ApiService,
    private _router: Router,
    private _ns: NotifierService,
    public _ss: SharingService
  ) { 

    this._ss.newButton = (this._ss.getUserSettings().type == 1) ? true : false;
    this._ss.saveButton = false;
    this._ss.listButton = false;

    this._ss.setSectionTitle('Ausencias');

    this.addButton = this._ss.$create.subscribe(() => {
      //this.newHolidays = true;
      //this.isCalendar = false;
      this._router.navigateByUrl('/ausencias/nueva');
    });

    this.range.controls.start.setValue(moment().format('YYYY-MM-DD'));
    this.range.controls.end.setValue(moment().clone().endOf('month').format('YYYY-MM-DD'));
  }

  ngOnInit(): void {
    if(this._ss.getUserSettings().type == 1) {
      this.getCompanyHolidaysInDays(this.range.controls.start.value,  this.range.controls.end.value);
    }else {
      this.getEmployeeHolidaysInDays(this.range.controls.start.value,  this.range.controls.end.value);
    }
 
  }

  ngOnDestroy(){
    this.addButton.unsubscribe();
  }

  getCompanyHolidaysInDays(startDate, endDate){
    this._api.getCompanyHolidaysInDays(startDate, endDate).subscribe((response)=>{
      if(response.result == 'OK'){
        this.absences = response.data;
        this.absences.forEach((absence)=>{
          absence.checked = false;
          absence.isVisible = false;
        });
        console.log(this.absences);
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  getEmployeeHolidaysInDays(startDate, endDate){
    this._api.getEmployeeHolidaysInDays(startDate, endDate).subscribe((response)=>{
      if(response.result == 'OK'){
        this.absences = response.data;
        console.log(this.absences);
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  getTypeColor(id){
    return this.absencesTypes.find(x => x.id === id).color;
  }

  tryToSelectDateRange():void{
    if(this.range.controls['end'].value != null){
      let startDate = moment(this.range.controls['start'].value).format('YYYY-MM-DD');
      let endDate = moment(this.range.controls['end'].value).format('YYYY-MM-DD');
      (this._ss.getUserSettings().type == 1) ? this.getCompanyHolidaysInDays(startDate, endDate) : this.getEmployeeHolidaysInDays(startDate, endDate);
    }
  }

  editAbsence(id, id_user){
    console.log(id_user);
    this.absences.forEach((absence)=>{
      if(absence.id != id){
        absence.isVisible = false;
      }
    });
    //this.absences.find(x => x.id == id).isVisible = !this.absences.find(x => x.id == id).isVisible;
    this._router.navigateByUrl('/ausencias/'+id+'/empleado/'+id_user+'/editar');
  }

  checkAllAbsences(checked){
    this.absences.forEach(absence => {
      absence.checked = checked;
    });

    if(checked){
      this.deleteabsences = this.absences.filter(x => x.checked == checked);
    }else{
      this.deleteabsences = [];
    }
  }

  checkAbsenceToDelete(id,status){
    this.absences.find( x => x.id == id).checked = status;

    if(status){
      this.deleteabsences.push({id: id});
    }else{
      this.deleteabsences = this.deleteabsences.filter(x => x.id != id);
    }
  }

  delAbsences(){
    this._api.delCompanyHolidays(this.deleteabsences).subscribe((response)=>{
      console.log(response);
      if(response.result == 'OK'){
        this._ns.notify('success','Eliminado correctamente!');
        this.ngOnInit();
      }else{
        this._ns.notify('error','Error: al eliminar las vacaciones.');
      }
    },(err)=>{
      this._ss.errorHandler(err);
    });
  }

  showAbsencesCalendar(status){
    this.isCalendar = status;
    if(status){
      (this._ss.getUserSettings().type == 1) ? this.getCompanyHolidaysInDays(this.range.controls.start.value, this.range.controls.end.value) :  this.getEmployeeHolidaysInDays(this.range.controls.start.value, this.range.controls.end.value);
    }
  }

}
