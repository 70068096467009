import { Component, OnInit } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    public _ss:SharingService
  ) {
    _ss.setSectionTitle("Inicio");
  }

  ngOnInit(): void {
  }

}
