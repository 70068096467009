

<form [formGroup]="loginForm" class="form-group login"  (ngSubmit)="submitForm(loginForm.value)" *ngIf="switchOption == 'login'">
    <label for="username">Email / NIF</label>
    <input type="text" formControlName="username" class="form-control" autocomplete="off" name="username" placeholder="NIF / Email" />

    <label for="password">Contraseña</label>
    <input type="password" formControlName="password" class="form-control" name="password" placeholder="contraseña" />

    <button>Entrar</button>

    <a (click)="showModal()">¿Olvidaste la contraseña?</a>
</form>

<div class="modal" *ngIf="isRecoveryMode" [@simpleFadeAnimation]>
    <div class="modal-content">
        <span class="close" (click)="isRecoveryMode = false"><i class="fas fa-times"></i></span>
        <h1>Recuperar contraseña</h1>
        <p class="info">Introduce tu email y recibirás un correo con las instrucciones</p>
        <form>
            <div class="row col-1">
                <div class="column">
                    <input name="email" type="email" [(ngModel)]="email" placeholder="correo electrónico" />
                </div>
            </div>
            <div class="row col-1">
                <button class="btn" (click)="recoverPass()">Recuperar contraseña</button>
            </div>
        </form>
    </div>
</div>