import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';
import * as moment from 'moment';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-journal-summary-details',
  templateUrl: './journal-summary-details.component.html',
  styleUrls: ['./journal-summary-details.component.scss'],
  animations:[
    trigger('simpleFadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [ style({opacity: 0}), animate(600) ]),
      transition(':leave', animate(600, style({opacity: 0}))) ])
  ]
})
export class JournalSummaryDetailsComponent implements OnInit {

  checksInDay = {checks:[], hours:0, minutes:0};
  checkDay;
  counter = {hours:0, minutes:0};
  employee:any = {};
  date;
  @Output() journal;
  journalIsEdit = false;
  goToList;

  constructor(
    private activatedRouter: ActivatedRoute,
    private router: Router,
    public _ss:SharingService,
    private _ns: NotifierService,
    private _api: ApiService
  ) { 
    this._ss.saveButton = false;
    this._ss.newButton = false;
    this._ss.listButton = true;

    this.goToList = this._ss.$golist.subscribe(() => {
      this.router.navigateByUrl('/empleado/lista');
    });

    moment.locale('es');
    let params = this.activatedRouter.snapshot.params;
    this.employee.id = (params.id) ? params.id : this._ss.getUserSettings().id;
    (params.date) ? this.date = params.date : null;
  }

  ngOnInit(): void {
    this.getEmployeeDetails();
    this.getChecksInDay();
  }

  getEmployeeDetails(){
    this._api.getCompanyEmployeeDetails(this.employee.id).subscribe((response)=>{
      if(response){
        this.employee = response;
        this._ss.setSectionTitle(this.employee.name+' '+this.employee.surnames);
      }else{
        this._ns.notify('error','Error: al cargar los datos de usuario');
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  getChecksInDay(){
    this._api.getUserChecksInDay(this.date, this.employee.id).subscribe((response)=>{
      if(response){
        console.log(response);
        this.checksInDay = response;
        this.checkDay = moment(this.checksInDay.checks[0].checkin).format('DD MMMM YYYY');
        this.counter.hours = this.checksInDay.hours;
        this.counter.minutes = this.checksInDay.minutes;
        if(this.checksInDay.checks.length){
          this.checksInDay.checks.forEach(checktime => {
            checktime.op_in = (checktime.origin == 4 && checktime.checkin) ? ' Hardware Biométrico (HB)' : checktime.op_in;
            checktime.op_out = (checktime.origin == 4 && checktime.checkout) ? ' Hardware Biométrico (HB)' : checktime.op_out;
          });
        }
      }else{
        this._ns.notify('error','Se ha producido u nerror al cargar el histórico');
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  modifyJournalUser(journal){
    this.journal = journal;
    this.journalIsEdit = true;
  }

  onModifyJournal(){
    this.journalIsEdit = false;
    this.getChecksInDay();
  }
  
  ngOnDestroy(){
    this.goToList.unsubscribe();
  }
}
