<div class="content">
    <div class="wrap">
        <div class="center-content" [ngClass]="{'collapse' : isCollapse }">
            <div class="title">
                <h1><i class="far fa-building"></i>{{centerTitle}}</h1>
                <span class="btn-close" (click)="showDetails()">
                    <i class="fas fa-chevron-down"></i>
                </span>
            </div>
            <form [formGroup]="centerForm" class="center-details">
                <div class="row space-between col-1">
                    <div class="column">
                        <label for="name">Nombre*</label>
                        <input name="name" type="text" formControlName="name" #name placeholder="Nombre" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" required />
                    </div>
                </div>
                <div class="row space-between col-2">
                    <div class="column">
                        <label for="worker_number">Calle*</label>
                        <input name="worker_number" type="text" formControlName="streetName" #streetName placeholder="Nº trabajador" [ngClass]="{ 'is-invalid': submitted && f.streetName.errors }" />
                    </div>
                    <div class="column">
                        <label for="hours_week">Provincia*</label>
                        <input name="hours_week" type="text" formControlName="province" #province placeholder="Nº horas" [ngClass]="{ 'is-invalid': submitted && f.province.errors }" />
                    </div>
                </div>
                <div class="row space-between col-3">
                    <div class="column">
                        <label for="phone">Numero*</label>
                        <input name="phone" type="text" formControlName="streetNumber" #streetNumber placeholder="Número" [ngClass]="{ 'is-invalid': submitted && f.streetNumber.errors }" />
                    </div>
                    <div class="column">
                        <label for="phone">Ciudad*</label>
                        <input name="phone" type="phone" formControlName="council" #council placeholder="Teléfono" [ngClass]="{ 'is-invalid': submitted && f.council.errors }" />
                    </div>
                   <div class="column">
                        <label for="email">Código Postal*</label>
                        <input name="email" type="email" formControlName="postalCode" #postlaCode placeholder="Correo" [ngClass]="{ 'is-invalid': submitted && f.postalCode.errors }" required />
                   </div>
                </div>
            </form>
        </div>
        <app-timetable *ngIf="!newCenter" [idcenter]="f.id.value"></app-timetable>
    </div>
</div>