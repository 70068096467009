import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SharingService } from '../services/sharing.service';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor (
    private _sharingService: SharingService,
    private router: Router
    ) {
      this.router = router;
    }

    canActivate() {
      if (this._sharingService.isLoged()) {
        return true;
      }

      this.router.navigate(['/login']);
      return false;
    }

}
