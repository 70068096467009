// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiURL: 'http://localhost:7200/api/v1',
  //uploadURL: 'http://localhost:7200/api/v1/uploader',
  //apiTemplate: 'http://localhost:7100/api/v1',
  //staticURL: '/var/www/html/',
  apiURL:'https://rrhhservice.driver360.es/api/v1',
  uploadURL: 'https://rrhhservice.driver360.es/api/v1/uploader',
  apiTemplate: 'http://localhost:7100/api/v1',
  staticURL: 'https://multisitestaticdemo.driver360.es/journalcheckin/',
  adminURL: '/admin',
  publicURL:'/public'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
