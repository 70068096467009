import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-add-center',
  templateUrl: './add-center.component.html',
  styleUrls: ['./add-center.component.scss']
})
export class AddCenterComponent implements OnInit {

  submitted = false;
  centerForm:FormGroup;
  isCollapse = false;
  centerTitle = 'Nuevo Centro';
  newCenter = true;
  saveCenter;

  constructor(
    private formBuilder: FormBuilder,
    public _ss:SharingService,
    private _api : ApiService,
    private _activatedRoute:ActivatedRoute
  ) {
    this._ss.saveButton = false;
    this._ss.listButton = false;
    this._ss.newButton = false;
    this.saveCenter = this._ss.$save.subscribe(() => {
      
    });
    
    this.centerForm = this.formBuilder.group({
      id:[null],
      name:[null, Validators.required],
      province:[null,Validators.required],
      streetNumber:[null],
      streetName:[null,Validators.required],
      council:[null,Validators.required],
      postalCode:[null,Validators.required],
      longitude:[''],
      latitude:['']
    })
    let params = this._activatedRoute.snapshot.params;
    console.log(params);
    (params.id) ? this.centerForm.controls.id.setValue(params.id) : null;
    this._ss.setSectionTitle('Nuevo Centro');
   }

  ngOnInit(): void {
    this.getCenterDetails();
  }

  get f() { return this.centerForm.controls; }

  getCenterDetails(){
    this._api.getCompanyCenterDetails(this.centerForm.controls.id.value).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.data);
        this._ss.setSectionTitle('Editar Centro');
        this.centerTitle = response.data.name;
        this.centerForm.controls.id.setValue(response.data.id);
        this.centerForm.controls.name.setValue(response.data.name);
        this.centerForm.controls.streetName.setValue(response.data.address);
        this.centerForm.controls.council.setValue(response.data.locality);
        this.centerForm.controls.province.setValue(response.data.province);
        this.centerForm.controls.postalCode.setValue(response.data.postal_code);
        this.centerForm.controls.latitude.setValue(response.data.latitude);
        this.centerForm.controls.longitude.setValue(response.data.longitude);
        console.log(this.centerForm);
        this.newCenter = false;
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }
  
  showDetails(){
    this.isCollapse = !this.isCollapse;
  }
}
