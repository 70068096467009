<div class="content">
    <div class="wrap">
        <div class="row space-between col-2">
            <div class="column">

                <div class="content" *ngIf="_ss.refreshtable">
                    <div class="wrap">
                        <ngx-datatable
                        class="material"
                        [rows]="journalOptions"
                        [columnMode]="'force'"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        rowHeight="auto"
                        [sorts]="[{prop: 'created_at', dir: 'desc'}]"
                        [limit]="10"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [@simpleFadeAnimation]="'in'"
                        [selectionType]="'checkbox'"
                        [selectAllRowsOnPage]="false"
                        (activate)='onClick($event)'
                      >
                   
                    <ngx-datatable-column prop="name" name="NOMBRE"></ngx-datatable-column>
                    <ngx-datatable-column prop="active" name="ACTIVO">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <ui-switch [checked]="value" (change)="changeOptionActive(row)"></ui-switch>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <button class="btn btn-edit" (click)="setOption(row)"><i class="fas fa-pencil-alt"></i>Editar</button>
                        </ng-template>
                    </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                </div>

            </div>
            <div class="column">
                <form [formGroup]="optionForm" (ngSubmit)="saveOption()" class="add-option-container">
                    <h1>{{optiontitle}} <span *ngIf="f.id.value" (click)="resetForm()" class="clear-form"><i class="fas fa-times"></i></span></h1>
                    <div class="option-details">
                        <div class="row space-between col-2">
                            <div class="column">
                                <label for="option">Nombre:</label>
                                <input type="text" formControlName="name" name="name" #name placeholder="Nombre"  [ngClass]="{ 'is-invalid': submitted && f.name.errors }" required />
                            </div>
                            <div class="column">
                                <label>Estado:</label>
                                <ui-switch [checked]="f.active.value" (change)="changeOptionStatus($event)"></ui-switch>
                            </div>
                        </div>
                            <button class="btn">{{ btntitle }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
