<div class="content">
    <div class="wrap">
        <div class="employee-content" [ngClass]="{'collapse' : isCollapse }">
            <div class="title">
                <h1><i class="far fa-user"></i>{{usertitle}}</h1>
                <span class="btn-close" (click)="showDetails()">
                    <i class="fas fa-chevron-down"></i>
                </span>
            </div>
            <form [formGroup]="userForm" class="employee-details">
                <div class="row space-between col-3">
                    <div class="column">
                        <label for="nif">NIF*</label>
                        <input name="nif" type="text" formControlName="nif" #nif  placeholder="NIF" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" required />
                    </div>
                    <div class="column">
                        <label for="name">Nombre*</label>
                        <input name="name" type="text" formControlName="name" #name placeholder="Nombre" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" required />
                    </div>
                    <div class="column">
                        <label for="surnames">Apellidos*</label>
                        <input name="surnames" type="text" formControlName="surnames" #surnames placeholder="Apellidos" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" required />
                    </div>
                </div>
                <div class="row space-between col-2">
                    <div class="column">
                        <label for="worker_number">Nº Trabajador</label>
                        <input name="worker_number" type="text" formControlName="worker_number" #worker_number placeholder="Nº trabajador" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
                    </div>
                    <div class="column">
                        <label for="hours_week">Nº Horas</label>
                        <input name="hours_week" type="text" formControlName="hours_week" #hours_week placeholder="Nº horas" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
                    </div>
                </div>
                <div class="row space-between col-2">
                    <div class="column">
                        <label for="phone">Teléfono</label>
                        <input name="phone" type="phone" formControlName="phone" #phone placeholder="Teléfono" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
                    </div>
                   <div class="column">
                        <label for="email">Correo*</label>
                        <input name="email" type="email" formControlName="email" #email placeholder="Correo" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" required />
                   </div>
                </div>
                <div class="row generate" *ngIf="newUser">
                    <div class="column">
                        <label for="password">Contraseña</label>
                        <input name="password" type="text" formControlName="password" #password placeholder="Contraseña" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" required readonly/>
                    </div>
                    <a (click)="generatePassword()" class="btn-generate-pass">
                        <i class="fas fa-sync-alt"></i> <span>GENERAR PASSWORD</span>
                    </a>
                </div>
            </form>
        </div>
        <app-timetable *ngIf="!newUser" [iduser]="f.id.value"></app-timetable>
    </div>
</div>