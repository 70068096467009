import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';


@Component({
  selector: 'app-add-timetable',
  templateUrl: './add-timetable.component.html',
  styleUrls: ['./add-timetable.component.scss']
})
export class AddTimetableComponent implements OnInit {

  @Output() onCancelTimetable = new EventEmitter<any>();
  @Input() iduser;

  days = [{
    week_day:1,
    label:'Lunes',
    active: true,
    hour_in:'00:00',
    hour_out:'00:00',
    user_id: null
  },{
    week_day:2,
    label:'Martes',
    active: false,
    hour_in:'00:00',
    hour_out:'00:00',
    user_id: null
  },{
    week_day:3,
    label:'Miercoles',
    active: false,
    hour_in:'00:00',
    hour_out:'00:00',
    user_id: null
  },{
    week_day:4,
    label:'Jueves',
    active: false,
    hour_in:'00:00',
    hour_out:'00:00',
    user_id: null
  },{
    week_day:5,
    label:'Viernes',
    active: false,
    hour_in:'00:00',
    hour_out:'00:00',
    user_id: null
  },
  {
    week_day:6,
    label:'Sábado',
    active: false,
    hour_in:'00:00',
    hour_out:'00:00',
    user_id: null
  },{
    week_day:7,
    label:'Domingo',
    active: false,
    hour_in:'00:00',
    hour_out:'00:00',
    user_id: null
  }];

  hourForm: FormGroup;

  constructor(
    private formBuilder:FormBuilder,
    private _api: ApiService,
    public _ss: SharingService,
    private _ns: NotifierService
  ) {
    this.hourForm = this.formBuilder.group({
      hour_in: ['', Validators.required],
      hour_out: ['', Validators.required]
    });
   }

  ngOnInit(): void {
  }

  addDay(day){
    console.log(day);
    this.days.find(x => x.week_day  == day.week_day).active = !this.days.find(x => x.week_day  == day.week_day).active;
  }

  saveTimeTable(){
    console.log(this.days);
    if(!this.hourForm.invalid){
      this.days.forEach(day => {
        day.hour_in = this.hourForm.controls.hour_in.value;
        day.hour_out = this.hourForm.controls.hour_out.value;
        day.user_id = (this.iduser) ? this.iduser : null;
      });
      let timetable = this.days.filter(x => x.active == true);
      this.addUserTimetable(timetable);
    }else{
      this._ns.notify('error','Error: algunos campos son obligatorios.');
    }
  
  }


  addUserTimetable(timetable){
    this._api.addUserTimetable(timetable).subscribe((response)=>{
      if(response.result == 'OK'){
        this._ns.notify('success','Horario guardado correctamente!');
      }else{
        this._ns.notify('error','Error: se ha producido un error en el guardado.');
      }
      this.hideModalTimeTable();
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  hideModalTimeTable(){
    console.log('cancelando...');
    this.onCancelTimetable.emit(false);
  }
  

}
