<div class="content">
    <div class="wrap">
        <div class="holidays-content">
            <h1><i class="fas fa-users-slash"></i>{{title}}</h1>
            <form  [formGroup]='holidaysForm' class="holidays-details">
                <div class="row space-between col-1" [ngClass]="{'col-2': this.f.id.value == null}" >
                    <div class="column" *ngIf="this.f.id.value == null">
                        <label for="id_user">Empleado</label>
                        <input type="text" [(ngModel)]="employee" formControlName="employees" (keyup)="getEmployeesId($event)"  list="employeeList" autocomplete="off" [readonly]="isEdit || isFestive"> 
                        <datalist id="employeeList">
                            <option *ngFor="let employee of employeesList" [value]="employee.name">{{employee.name}} {{employee.surnames}} ( {{employee.nif}} )</option>
                        </datalist>                
                    </div>
                    <div class="column" >
                        <label for="type_id">Tipo</label>
                        <select (change)="changeAbsenceType($event.target.value)"  name="type_id" formControlName="type_id" #type_id>
                            <option [value]="null" [selected]="f.type_id.value == null" disabled>Selecciona un tipo</option>
                            <option *ngFor="let type of holidayTypes" [value]="type.id" [disabled]="isEmployee">
                                {{ type.description }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row col-1" *ngIf="holidayEmployees.length">
                    <div class="employee-list column" [ngClass]="{'collapse': isCollapse}">
                        <h1>Empleados seleccionados <a (click)="isCollapse = !isCollapse"><i class="fas fa-chevron-down"></i></a></h1>
                        <div class="details">
                            <ul >
                                <li *ngFor="let hemployee of holidayEmployees">
                                    <div class="avatar"></div>
                                    <div class="details">
                                        <h1>{{ hemployee.name }} {{hemployee.surnames}} ( {{hemployee.nif}} )</h1> 
                                        <a class="email" href="mailto:{{hemployee.email}}"><i class="far fa-envelope"></i>{{hemployee.email}}</a>
                                    </div>
                                    <a *ngIf="!isEdit" (click)="deleteEmployee(hemployee.id)" class="delete"><i class="fas fa-times"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row space-between col-2">
                    <div class="column">
                        <label for="init">Fecha Inicio</label>
                        <input type="datetime-local" formControlName="date_start" #date_start [readonly]="isEmployee"/>
                    </div>
                    <div class="column">
                        <label for="init">Fecha Fin</label>
                        <input type="datetime-local" formControlName="date_end" #date_end [readonly]="isEmployee"/>
                    </div>
                </div>
                <div class="row col-1">
                    <div class="column">
                        <label for="observations">Observaciones</label>
                        <input type="text" name="observations" formControlName="observations" #observations placeholder="observaciones" [readonly]="isEmployee"/>    
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
