import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RowHeightCache } from '@swimlane/ngx-datatable';
import { NotifierService } from 'angular-notifier';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-abscense-options',
  templateUrl: './abscense-options.component.html',
  styleUrls: ['./abscense-options.component.scss'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [ style({opacity: 0}), animate(600) ]),
      transition(':leave', animate(600, style({opacity: 0}))) ])
  ]
})
export class AbscenseOptionsComponent implements OnInit {

  optiontitle:String = 'Nueva opción';
  btntitle:String  = 'AÑADIR';
  journalOptions = [];
  submitted = false;
  optionForm:FormGroup;
  constructor(
    public _ss:SharingService,
    public _ns:NotifierService,
    private _api:ApiService,
    private formBuilder:FormBuilder
  ) { 
    this._ss.setSectionTitle('Opciones Ausencia');
    this._ss.newButton = false;
    this._ss.listButton = false;
    this._ss.saveButton = false;

    this.optionForm = this.formBuilder.group({
      id:[null],
      name:[null, Validators.required],
      active:[true, Validators.required]
    });
  }

  ngOnInit(): void {
    this.getJournalOptions();
  }

  get f() { return this.optionForm.controls; }

  saveOption(){
    console.log("Guardando....");
    if(!this.optionForm.invalid){
      console.log(this.optionForm.controls.id.value);
      (!this.optionForm.controls.id.value) ? this.saveJournalOption() : this.modifyJournalOption(this.optionForm.value);
    }else{
      this._ns.notify("error","Error: Algunos campos son obligatorios!");
    }
  }

  getJournalOptions(){
    this._api.getJournalOptions(this._ss.getUserSettings().id).subscribe((response)=>{
      if(response){
        this.journalOptions = response.journal_options;
        this.journalOptions.forEach(option => {
          option.active = (option.active == 1) ? true : false;
        });
        console.log(this.journalOptions);
        this._ss.refreshtable = true;
      }else{

      }
    },(err)=>{
      this._ss.errorHandler(err);
    });
  }

  setOption(row){
    this.optiontitle = 'Modificar opción';
    this.btntitle = 'GUARDAR';
    this.optionForm.controls.id.setValue(row.id);
    this.optionForm.controls.name.setValue(row.name);
    this.optionForm.controls.active.setValue(row.active);
  }

  saveJournalOption(){
    this._ss.refreshtable = false;
    this.submitted = true;
    this._api.addJournalOption(this.optionForm.value).subscribe((response)=>{
      if(response){
        console.log(response);
      
        this._ns.notify("success","Opción creada correctamente!");
        this.optionForm.controls['id'].setValue(response.id);
        this.journalOptions.push(this.optionForm.value);
        this.resetForm();
        this._ss.refreshtable = true;
      }else{
        this._ns.notify("error","Error: no se ha creado la opción");
      }
    },(err)=>{
      this._ss.errorHandler(err);
    });
  }

  modifyJournalOption(option){
    this._ss.refreshtable = false;
    console.log('modificando opción');
    this._api.setJournalOption(option).subscribe((response)=>{
      if(response.result == 'OK'){
        this.journalOptions.find( x => x.id == option.id).name = option.name;
        this.journalOptions.find( x => x.id == option.id).active = option.active;
        this._ns.notify('success','Guardado correctamente!');
        this.resetForm();
        this._ss.refreshtable = true;
      }else{
        this._ns.notify('error','Error: al modificar la opción!');
      }
    },((err)=> {
      this._ss.errorHandler(err);
    }));
  }

  onClick(event){

  }

  resetForm(){
    this.optiontitle = 'Nueva opción';
    this.btntitle = 'AÑADIR';
    this.optionForm.reset();
    this.optionForm.patchValue({
      id:null,
      name:null,
      active:true
    });
  }

  changeOptionActive(option){
    this.journalOptions.find(x => x.id == option.id).active = !this.journalOptions.find(x => x.id == option.id).active;
    console.log(option);
    this.modifyJournalOption(option);
  }

  changeOptionStatus(value){
    console.log(value);
    this.optionForm.controls.active.setValue(value);
  }

}
