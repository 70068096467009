import { getLocaleExtraDayPeriods } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class AlertsComponent implements OnInit {

  alerts = [];
  title = 'Alertas';
  deletealerts = [];

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  constructor(
    public _ss: SharingService,
    private _api: ApiService,
    private _ns: NotifierService
  ) { 
    this._ss.saveButton = false;
    this._ss.newButton = false;
    this._ss.listButton = false;
    this._ss.setSectionTitle('Alertas');
    this.range.controls['start'].setValue(moment().clone().startOf('month').format('YYYY-MM-DD'));
    this.range.controls['end'].setValue(moment().clone().endOf('month').format('YYYY-MM-DD'));
  }

  ngOnInit(): void {
    this.getAlerts(this.range.controls.start.value, this.range.controls.end.value);
  }

  getAlerts(startDate, endDate){
    this._api.getCompanyAlerts(startDate, endDate).subscribe((response)=>{
      if(response){
        console.log(response);
        this.alerts = response.alerts;
      }else{
        this._ns.notify('error','Error: Al intentar cargar las alertas.');
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  deleteAlerts(){
    this._api.deleteCompanyAlert(this.deletealerts).subscribe((response)=>{
      if(response.result == 'OK'){
        this._ns.notify('success','Alertas eliminadas correctamente!');
        this.getAlerts(this.range.controls.start.value, this.range.controls.end.value);
      }else{
        this._ns.notify('error','Error: Al eliminar las alertas.');
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  checkAlertToDelete(alert, status){

    this.alerts.find( x => x.id === alert).checked = status;

    if(status){
      this.deletealerts.push({id: alert});
    }else{
      this.deletealerts = this.deletealerts.filter(x => x.id != alert);
    }

    console.log(this.deletealerts);
  }

  checkAllAlerts(checked){
    this.alerts.forEach(alert => {
      alert.checked = checked;
    });

    if(checked){
      this.deletealerts = this.alerts.filter(x => x.checked == checked);
    }else{
      this.deletealerts = [];
    }
  
  }


  tryToSelectDateRange():void{
    if(this.range.controls['end'].value != null){
      let startDate = moment(this.range.controls['start'].value).format('YYYY-MM-DD');
      let endDate = moment(this.range.controls['end'].value).format('YYYY-MM-DD');
      this.getAlerts(startDate, endDate);
    }
  }
}
