
<div class="header">
    <div class="options-left">
        <a *ngIf="_ss.listButton" class="menu-btn" (click)="onList()" #listbutton>
            <div class="icon">
                <img src="../../../assets/icons/toolbar/toolbar-to-list.svg">
            </div>
            Lista
        </a>
    </div>
    <h1>{{_ss.sectionTitle}}</h1>
    <div class="options-right">
        <a *ngIf="_ss.saveButton" class="menu-btn" (click)="onSave()" #savebutton>
            <div class="icon">
                <img src="../../../assets/icons/toolbar/toolbar-save-and-edit.svg">
            </div>
            Guardar
        </a>
        <a *ngIf="_ss.newButton" class="menu-btn" (click)="onCreate()" #newbutton>
            <div class="icon">
                <img src="../../../assets/icons/toolbar/toolbar-add.svg">
            </div>
            Crear
        </a>
    </div>
    <span class="superadmin" *ngIf="_ss.isSuperAdmin">*Superadmin</span>
</div>