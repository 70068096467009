<div class="content">
    <div class="wrap">
        <div class="center-list">
            <div class="center-box" *ngFor="let center of centers" (click)="goToCenter(center.id)">
                <h1><i class="fas fa-building"></i>{{center.name}}</h1>
                <div class="center-map">
                    <agm-map #AgmMap [latitude]="center.latitude" [longitude]="center.longitude" [zoom]="18" (idle)="mapIdle()">
                        <agm-marker (markerClick)="showInfo(infoWindow)" *ngFor="let center of centers" [latitude]="center.latitude" [longitude]="center.longitude" [label]="center.name">
                            
                        </agm-marker>
                    </agm-map>
                </div>
                <div class="center-content">
                    <p><span>Calle: </span>{{center.address}}</p>
                    <p><span>Provincia: </span>{{center.province}}<span> C.P.:</span> {{center.postal_code}}</p>
                    <p>
                        <span>lon:</span>{{center.longitude}}
                        <span>lat:</span>{{center.latitude}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>