<div class="content">
    <div class="wrap">
        <ngx-datatable
        class="material"
        [rows]="employees"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        rowHeight="auto"
        [sorts]="[{prop: 'created_at', dir: 'desc'}]"
        [limit]="10"
        [headerHeight]="50"
        [footerHeight]="50"
        [@simpleFadeAnimation]="'in'"
        [selectionType]="'checkbox'"
        [selectAllRowsOnPage]="false"
        (activate)='onClick($event)'
      >
    <ngx-datatable-column prop="worker_number" name="NÚMERO"></ngx-datatable-column>
    <ngx-datatable-column prop="nif" name="NIF"></ngx-datatable-column>
    <ngx-datatable-column prop="name" name="NOMBRE"></ngx-datatable-column>
    <ngx-datatable-column prop="surnames" name="APELLIDOS"></ngx-datatable-column>
    <ngx-datatable-column prop="last_login" name="ÚLTIMA CONEXIÓN">
        <ng-template let-value="value" ngx-datatable-cell-template>
            {{value | date:'dd/MM/yyyy HH:mm'}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="alerts" name="ALERTAS">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <ui-switch [checked]="value" (change)="changeUserActiveAlert(row, $event)"></ui-switch>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="status" name="ACTIVO">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <ui-switch [checked]="value" (change)="changeUserActiveStatus(row, $event)"></ui-switch>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column>
        <ng-template  let-row="row" let-value="value" ngx-datatable-cell-template>
            <a class=" btn btn-edit" (click)="onEdit(row)"><i class="fas fa-pencil-alt "></i>Editar</a>
        </ng-template>
    </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
