<div class="modal">
    <div class="modal-box">
        <h1>Nuevo Horario</h1>
        <div class="content">
            <label>Escoja días de la semana:</label>
            <ul class="days">
                <li (click)="addDay(day)" *ngFor="let day of days" [ngClass]="{'active':day.active}">{{ day.label}}</li>
            </ul>
            <form [formGroup]="hourForm" class="row col-2 space-between">
                <div class="column">
                    <label>Hora entrada:</label>
                    <input type="time" formControlName="hour_in" #hour_in/>
                </div>
                <div class="column">
                    <label>Hora salida:</label>
                    <input type="time"formControlName="hour_out" hour_out />
                </div>
            </form>
            <div class="bar-buttons">
                <button (click)="hideModalTimeTable()">Cancelar</button>
                <button (click)="saveTimeTable()">Guardar</button>
            </div>
        </div>
    </div>
</div>

