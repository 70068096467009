export const menu = [{
  title:'Empleados',
  href:'/empleado/lista',
  icon:"fas fa-users",
  active:true
},{
  title:'Centros',
  href:'/centros',
  icon:"fas fa-building",
  active:true
},{
  title:'Mi jornada',
  href:'/jornada',
  icon:"fas fa-calendar-plus",
  active:true
},{
  title:'Historial',
  href:'/historial',
  icon:"fas fa-history",
  active:false
},{
  title:'Alertas',
  href:'/alertas',
  icon:"far fa-bell",
  active:true
},{
  title:'Ausencias',
  href:'/ausencias',
  icon:'fas fa-users-slash',
  active:true
},{
  title:'Gastos Empresa',
  href:'/gastos',
  icon:'far fa-credit-card',
  active:true
},{
  title:'Gastos',
  href:'/gastos/empleado',
  icon:'far fa-credit-card',
  active:false
},{
  title:'Opciones',
  href:'/opciones',
  icon:"fas fa-list-alt",
  active:true
},{
  title:'Ajustes',
  href:'/ajustes',
  icon:"fas fa-cog",
  active:true
}];

export const expenseTypes = [{
    id:1,
    color:'lightblue',
    label:'Desayuno',
    icon:'fas fa-coffee'
  },
  {
    id:2,
    color:'darkcyan',
    label:'Comida',
    icon:'fas fa-utensils'
  },{
    id:3,
    color:'lightseagreen',
    label:'Cena',
    icon:'fas fa-pizza-slice'
  },{
    id:4,
    color:'#318eec',
    label:'Gasolina',
    icon:'fas fa-gas-pump'
  },{
    id:5,
    color:'salmon',
    label:'Tren',
    icon:'fas fa-subway'
  },{
    id:6,
    color:'lightsalmon',
    label:'Avión',
    icon:'fas fa-plane'
  },{
    id:7,
    color:'lightcoral',
    label:'Taxi',
    icon:'fas fa-taxi'
  },{
    id:8,
    color:'#F0AD4E',
    label:'Autobús',
    icon:'fas fa-bus-alt'
  },{
    id:9,
    color:'violet',
    label:'Otros',
    icon:'fas fa-asterisk'
  }];

  export const absencesTypes = [{
    id:1,
    color:'#318eec',
    label:'Vacaciones',
  },{
    id:2,
    color:'darkcyan',
    label:'Festivo',
  },{
    id:3,
    color:'#F0AD4E',
    label:'Cita Médica',
  },{
    id:4,
    color:'lightcoral',
    label:'Baja laboral',
  }]