
<div class="content">
    <div class="wrap">
        <div class="expense-content">
            <h1><i class="far fa-credit-card"></i>Gastos</h1>
            <div class="expense-details">
                <div class="btn-bar">
                    <div class="left">
                    </div>
                    <div class="right">
                        <mat-form-field>
                            <mat-date-range-input [formGroup]="range" [rangePicker]="picker"  >
                                <input matStartDate #dateRangeStart formControlName="start" placeholder="Fecha Inicio" (click)="$event.target.select()" (dateChange)="tryToSelectDateRange()">
                                <input matEndDate #dateRangeEnd formControlName="end" placeholder="Fecha Final" (click)="$event.target.select()" (dateChange)="tryToSelectDateRange()">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker panelClass="btn-date"></mat-date-range-picker>
                            
                            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fech. Inicio incorrecta</mat-error>
                            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fech. Fin incorrecta</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <p class="alert" *ngIf="!expenses.length">Actualmente no tiene ningún gasto.</p>
                <div *ngIf="_ss.getUserSettings().type != 0">
                    <div class="expense" *ngFor="let expense of expenses">
                        <h1>{{expense.name}} {{expense.surnames}} ( {{expense.nif}} )</h1> <span clasS="price">{{expense.amount}} €</span>
                        <a class="btn btn-show-details" (click)="goToExpenseDetails(expense.user_id)"><i class="far fa-eye"></i>ver gastos</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
