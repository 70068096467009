import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';
import * as moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

@Component({
  selector: 'app-journal-summary',
  templateUrl: './journal-summary.component.html',
  styleUrls: ['./journal-summary.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class JournalSummaryComponent implements OnInit {


@ViewChild(MatDateRangePicker, {static:false})
picker: MatDateRangePicker<Date>;

  counter = {
    hours:0,
    minutes:0
  }

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  employee:any = {};
  history = [];
  firstdaymonth;
  lastdaymonth;

  goToList;

  constructor(
    public _ss: SharingService,
    private activatedRouter:ActivatedRoute,
    private router: Router,
    private _ns: NotifierService,
    private _api:ApiService
  ) {
    moment.locale('es');
    this._ss.saveButton = false;
    this._ss.newButton = false;
    this._ss.listButton = (this._ss.getUserSettings().id != 1) ? true : false;
    let params = this.activatedRouter.snapshot.params;
    this.employee.id = (params.id) ? params.id : this._ss.getUserSettings().id;
   

    this.range.controls['start'].setValue(moment().clone().startOf('month').format('YYYY-MM-DD'));
    this.range.controls['end'].setValue(moment().clone().endOf('month').format('YYYY-MM-DD'));
    this.goToList = this._ss.$golist.subscribe(() => {
      this.router.navigateByUrl('/empleado/lista');
    });
   }

  ngOnInit(): void {
    this.getEmployeeData();
  }

  getEmployeeData(){
    this._api.getCompanyEmployeeDetails(this.employee.id).subscribe((response)=>{
      if(response){
        this.employee = response;
        this.counter = this.calculateTime(this.employee.total);
        this._ss.setSectionTitle(this.employee.name+ ' '+this.employee.surnames);
      }else{
        this._ns.notify('error','Error: No se han podido cargar los datos del empleado');
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }

  calculateTime(minutes){
    let counter = {hours: 0, minutes: 0};
    if(minutes >= 60){
      counter.hours = Math.floor(minutes / 60);
      counter.minutes = minutes - (counter.hours * 60);
    }else{
      counter.hours = 0;
      counter.minutes = minutes;
    }
    return counter;
  }

  getEmployeeHistory(startDate, EndDate, emplooyeId){
    this._api.CheckWorksDayOfMonth(startDate, EndDate, emplooyeId).subscribe((response)=>{
      if(response){
        this.employee.days = response.data.days;
        let total = 0;
        this.employee.days.forEach(day => {
          total += day.minutes;
          let counter = this.calculateTime(day.minutes);
          day.hours = counter.hours;
          day.minutes = counter.minutes;
        });
        this.counter = this.calculateTime(total);
      }else{
        
      }
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }


  getEmployeeJournal(){
    console.log("Descargar pdf....");
    let startDate =  moment(this.range.controls['start'].value).format('YYYY-MM-DD');
    let endDate =  moment(this.range.controls['end'].value).format('YYYY-MM-DD');
    let employeeId = this.employee.id;
    this._api.getPdfByDay(startDate, endDate, employeeId).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.data);
        this.downloadPdf(response.data,"history-"+moment());
      }else{
        
      }
  });
}

downloadPdf(base64String, fileName){
  if(window.navigator && window.navigator.msSaveOrOpenBlob){ 
    // download PDF in IE
    let byteChar = atob(base64String);
    let byteArray = new Array(byteChar.length);
    for(let i = 0; i < byteChar.length; i++){
      byteArray[i] = byteChar.charCodeAt(i);
    }
    let uIntArray = new Uint8Array(byteArray);
    let blob = new Blob([uIntArray], {type : 'application/pdf'});
    window.navigator.msSaveOrOpenBlob(blob, `${fileName}.pdf`);
  } else {
    // Download PDF in Chrome etc.
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }
}

  tryToSelectDateRange():void{
    if(this.range.controls['end'].value != null){
      this.resetCounter();
      let startDate = moment(this.range.controls['start'].value).format('YYYY-MM-DD');
      let endDate = moment(this.range.controls['end'].value).format('YYYY-MM-DD');
      this.getEmployeeHistory(startDate, endDate, this.employee.id);
    }
  }

  resetCounter(){
    this.counter = {hours:0 ,minutes:0};
  }


  onClick(event){
    console.log(event);
    console.log(this.router.url);
    if (event.type === 'dblclick') {
      console.log(this.router.url);
      if(this.router.url != '/historial'){
        this.router.navigateByUrl('/empleado/'+this.employee.id+'/jornada/'+event.row.day+'/detalles');
      }else{
        this.router.navigateByUrl('/historial/'+event.row.day+'/detalles');
      }
    }
  }


  ngOnDestroy(){
    this.goToList.unsubscribe();
  }

}
