<div class="content">
    <div class="modal-box">
        <h1>{{title}}</h1>
        <div class="modal-content">
            <form [formGroup]="formOption" (ngSubmit)="saveSelection()" class="row space-between col-1">
                <div class="column">
                    <label for="option">Seleccione una opción:</label>
                    <select formControlName="id" #id (change)="switchCheckOption($event.target)">
                        <option [value]="null" disabled>Seleccione una opción</option>
                        <option *ngFor="let option of options" [attr.data-type]="option.type" [value]="option.id">{{option.name}}</option>
                    </select>
                </div>
                <div class="column">
                    <button>Seleccionar</button>
                </div>
            </form>          
        </div>
    </div>    
</div>
