<div class="content">
    <div class="modal-box">
        <h1>{{title}}</h1>
        <form [formGroup]="journalForm" (ngSubmit)="modifyJournal()" class="modal-content">
            <div class="row col-2 space-between">
                <div class="column">
                    <label>Entrada</label>
                    <input type="datetime-local" formControlName="checkin" />
                </div>
                <div class="column">
                    <label>Salida</label>
                    <input type="datetime-local" formControlName="checkout" />
                </div>
                <!--
                <div class="column">
                    <label>Lugar</label>
                    <select formControlName="option_in" (change)="isAnOption('checkin',$event.target)">
                        <option [selected]="f.option_in.value == null" disabled>Seleccione una opcion</option>
                        <option *ngFor="let option of options" [attr.data-type]="option.type" [value]="option.id">
                            {{option.name}}
                        </option>
                    </select>
                </div>
                -->
            </div>
            <!--
            <div class="row col-2 space-between">
                <div class="column">
                    <label>Salida</label>
                    <input type="datetime-local" formControlName="checkout" />
                </div>
                <div class="column">
                    <label>Lugar</label>
                    <select formControlName="option_out" (change)="isAnOption('checkout',$event.target)">
                        <option [selected]="f.option_out.value == null" disabled>Seleccione una opcion</option>
                        <option *ngFor="let option of options" [attr.data-type]="option.type" [value]="option.id">
                            {{option.name}}
                        </option>
                    </select>
                </div>
            </div>-->
            <div class="row space-between">
                <button class="btn btn-edit">GUARDAR</button>
                <button class="btn btn-edit" (click)="closeJournalEditor()">CANCELAR</button>
            </div>
        </form>
    </div>
</div>