<div class="content">
    <div class="wrap">
        <div class="absences-content">
            <h1><i class="fas fa-users-slash"></i>Ausencias
                <div class="btn-calendar">
                    <a (click)="showAbsencesCalendar(false)" [ngClass]="{'active':!isCalendar}">Tarjetas</a>
                    <a (click)="showAbsencesCalendar(true)" [ngClass]="{'active':isCalendar}">Calendario</a>
                </div>
            </h1>
            <div class="absences-details">
                <div class="btn-bar">
                    <div class="left">
                        <span class="select-all" *ngIf="absences.length && _ss.getUserSettings().type == 1 && !isCalendar">
                            <input type='checkbox' (change)='checkAllAbsences($event.target.checked)'/>
                            <label>Seleccionar todos</label>
                        </span>
                        <ul class="info-bar">
                            <li *ngFor="let type of absencesTypes"><span class="circle" [ngStyle]="{'background-color': getTypeColor(type.id)}"></span>{{type.label}}</li>
                        </ul>
                    </div>
                    <div class="right">
                        <span *ngIf="deleteabsences.length && _ss.getUserSettings().type == 1" class="btn-delete" (click)="delAbsences()">
                            <i class="far fa-trash-alt"></i>
                            Eliminar
                        </span>
                        <mat-form-field *ngIf="!isCalendar">
                            <mat-date-range-input [formGroup]="range" [rangePicker]="picker"  >
                                <input matStartDate #dateRangeStart formControlName="start" placeholder="Fecha Inicio" (click)="$event.target.select()" (dateChange)="tryToSelectDateRange()">
                                <input matEndDate #dateRangeEnd formControlName="end" placeholder="Fecha Final" (click)="$event.target.select()" (dateChange)="tryToSelectDateRange()">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker panelClass="btn-date"></mat-date-range-picker>
                            
                            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fecha inicio incorrecta</mat-error>
                            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha final incorrecta</mat-error>
                        </mat-form-field>
                    </div>
                </div>

               <!-- Formulario para añadir vacaciones-->
                <!--<app-add-holidays *ngIf="newHolidays"></app-add-holidays>-->

                <!-- Calendario -->
                <app-holidays-calendar *ngIf="isCalendar" [absences]="absences"></app-holidays-calendar>
               
                <!-- Tarjetas -->
                <p class="alert" *ngIf="!absences.length && !isCalendar">Actualmente no tiene vacaciones programadas!</p>
                <ul class="absences" *ngIf="!isCalendar">
                    <div *ngFor="let absence of absences">
                    <li>
                        <input *ngIf="_ss.getUserSettings().type == 1" type="checkbox" [(ngModel)]="absence.checked" (change)="checkAbsenceToDelete(absence.id, $event.target.checked)" />
                        <h1 *ngIf="absence.user_name">{{absence.user_name}}</h1>
                        <h1 *ngIf="absence.id_absences_type == 2 && this._ss.getUserSettings().type == 1">{{absence.observations}}</h1>
                        <h1 *ngIf="absence.observations && this._ss.getUserSettings().type == 0">{{absence.observations}}</h1>
                        <div class="date">
                            <div class="column">
                                <span>Fecha Inicio</span>
                                <p>{{absence.date_start | date: 'dd/MM/YYYY'}}</p>
                            </div>
                        </div>
                        <div class="date">
                            <div class="column">
                                <span>Fecha Final</span>
                                <p>{{absence.date_end  | date: 'dd/MM/YYYY'}}</p>
                            </div>
                        </div>
                        <span class="absence-type" [ngStyle]="{'background-color': getTypeColor(absence.id_absences_type)}">{{absence.description}}</span>
                        <a *ngIf="_ss.getUserSettings().type == 1" (click)="editAbsence(absence.id, absence.id_user)" class="btn btn-edit"><i class="far fa-edit"></i> Editar</a>
                        <a *ngIf="_ss.getUserSettings().type == 0" (click)="editAbsence(absence.id, absence.id_user)" class="btn btn-see"><i class="far fa-eye"></i> Ver detalles</a>
                    </li>
                    <app-add-holidays *ngIf="absence.isVisible" [id_holidays]="absence.id" [id_user]="absence.id_user"></app-add-holidays>
                    </div>
                </ul>
                
            </div>
        </div>
       
    </div>
</div>