import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './guards';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { SettingsComponent } from './components/settings/settings.component';
import { JournalComponent } from './components/journal/journal.component';
import { HeaderComponent } from './components/header/header.component';
import { RoleGuardService } from './guards/role-guard.service';
import { ListUsersComponent } from './components/users/list-users/list-users.component';
import { ListCentersComponent } from './components/centers/list-centers/list-centers.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AgmCoreModule } from '@agm/core';
import { AddUserComponent } from './components/users/add-user/add-user.component';
import { SharingService } from './services/sharing.service';
import { CommonModule, registerLocaleData } from '@angular/common';
import { AbscenseOptionsComponent } from './components/abscense-options/abscense-options.component';
import { ApiService } from './services/api.service';
import { JournalOptionsComponent } from './components/journal/journal-options/journal-options.component';
import { JournalSummaryComponent } from './components/journal/journal-summary/journal-summary.component';
import localeEs from '@angular/common/locales/es';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { JournalSummaryDetailsComponent } from './components/journal/journal-summary-details/journal-summary-details.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { TimetableComponent } from './components/timetable/timetable.component';
import { AddTimetableComponent } from './components/timetable/add-timetable/add-timetable.component';
import { JournalSummaryDetailsEditComponent } from './components/journal/journal-summary-details-edit/journal-summary-details-edit.component';
import { AddCenterComponent } from './components/centers/add-center/add-center.component';
import { HolidaysComponent } from './components/holidays/holidays.component';
import { AddHolidaysComponent } from './components/holidays/add-holidays/add-holidays.component';
import { ExpensesComponent } from './components/expenses/expenses.component';
import { AddExpensesComponent } from './components/expenses/add-expenses/add-expenses.component';
import { ExpenseDetailsComponent } from './components/expenses/expense-details/expense-details.component';
import { HolidaysCalendarComponent } from './components/holidays/holidays-calendar/holidays-calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FileUploadModule } from 'ng2-file-upload';



const options: NotifierOptions = {
  position: {
      horizontal: {
          position: 'right',
          distance: 12
      },
      vertical: {
          position: 'bottom',
          distance: 50,
          gap: 10
      }
  },
  theme: 'material',
  behaviour: {
      autoHide: 3500,
      onClick: false,
      onMouseover: 'pauseAutoHide',
      showDismissButton: true,
      stacking: 4
  },
  animations: {
      enabled: true,
      show: {
          preset: 'slide',
          speed: 300,
          easing: 'ease'
      },
      hide: {
          preset: 'fade',
          speed: 300,
          easing: 'ease',
          offset: 50
      },
      shift: {
          speed: 300,
          easing: 'ease'
      },
      overlap: 150
  }
};

const routes: Routes = [
  { path: 'inicio', component: HomeComponent, canActivate:[AuthGuard]},
  { path:'ajustes', component: SettingsComponent, canActivate: [AuthGuard]},
  { path:'jornada', canActivate: [AuthGuard], children:[
    {path:'', component: JournalComponent},
  ]},
  {path: 'historial',
    children:[
      { path:'',  component: JournalSummaryComponent},
      { path:':date/detalles', component: JournalSummaryDetailsComponent}
    ]
  },
  { path:'empleado', canActivate: [RoleGuardService], data:{role:1},
    children: [
      { path: '', redirectTo: 'lista', pathMatch: 'full' },
      { path: 'lista', component: ListUsersComponent},
      { path: 'nuevo', component: AddUserComponent },
      { path: ':id/editar', component: AddUserComponent},
      { path:':id/jornada', component: JournalSummaryComponent},
      { path:':id/jornada/:date/detalles', component: JournalSummaryDetailsComponent}
    ]
  },
  {
    path:'ausencias', 
    children: [
      { path:'', component:HolidaysComponent},
      { path:'calendario', component:HolidaysCalendarComponent},
      { path:':id/empleado/:iduser/editar', component:AddHolidaysComponent},
      { path:'nueva', component:AddHolidaysComponent},
    ]
  },
  {
    path:'gastos', 
    children: [
      { path:'', component:ExpensesComponent},
      { path:'empleado', component:ExpenseDetailsComponent},
      { path:'empleado/:id', component:ExpenseDetailsComponent},
      { path:':id/editar', component:AddExpensesComponent},
      { path:'nuevo', component:AddExpensesComponent}
    ]
  },
  {path: 'alertas', component:AlertsComponent, canActivate:[RoleGuardService], data:{role:1}},
  { path:'opciones', canActivate:[RoleGuardService], data:{role:1},
    children:[
      {path:'', component: AbscenseOptionsComponent},
      {path:'nueva', component: AbscenseOptionsComponent}
    ]
  },
  { path:'centros', canActivate: [RoleGuardService], data:{role:1},
    children:[
      { path: '', component: ListCentersComponent},
      { path: ':id', component: AddCenterComponent}
    ]
  },
  { path: 'login', component: LoginComponent},
  { path:"**", pathMatch:"full", redirectTo:"jornada"},
];

registerLocaleData(localeEs, 'es')

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuComponent,
    FooterComponent,
    HomeComponent,
    AddCenterComponent,
    AddUserComponent,
    SettingsComponent,
    JournalComponent,
    HeaderComponent,
    ListUsersComponent,
    ListCentersComponent,
    AbscenseOptionsComponent,
    JournalOptionsComponent,
    JournalSummaryComponent,
    JournalSummaryDetailsComponent,
    AlertsComponent,
    TimetableComponent,
    AddTimetableComponent,
    JournalSummaryDetailsEditComponent,
    HolidaysComponent,
    AddHolidaysComponent,
    HolidaysCalendarComponent,
    ExpensesComponent,
    ExpenseDetailsComponent,
    AddExpensesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    UiSwitchModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB_pDhXSS3Sw2dbxPaB9TdOpqPK9n0zbjQ',
      language: 'es',
      libraries: ['geometry', 'places']
    }),
    AppRoutingModule,
    FileUploadModule,
    HttpModule,
    HttpClientModule,
    NotifierModule.withConfig(options),
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    FullCalendarModule,
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'ignore'}),
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
    //RouterModule.forRoot(routes, { useHash: true })
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    SharingService,
    ApiService,
    { provide: LOCALE_ID, useValue: "es-ES" }],
    
  bootstrap: [AppComponent]
})
export class AppModule { }
