import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';
import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ApiService } from 'src/app/services/api.service';
import { SharingService } from 'src/app/services/sharing.service';
import * as moment from 'moment';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-add-expenses',
  templateUrl: './add-expenses.component.html',
  styleUrls: ['./add-expenses.component.scss']
})
export class AddExpensesComponent implements OnInit {

  expenseTypes = [];
  expenseForm:FormGroup;
  goList;
  saveExpenseEvent;
  isCollapse = false;
  title:String = 'Nuevo gasto';
  submitted = false;
  isTicketDisplayed=false;
  isEdit=false;


  filename;
  isuploadingProgress;
  uploadStatusProgress;
  uploader:FileUploader;
  hasBaseDropZoneOver:boolean;
  response:String;
  saveWithImage = false;

 

  constructor(
    private formBuilder: FormBuilder,
    public _ss:SharingService,
    public _ns: NotifierService,
    private _activatedRouter: ActivatedRoute,
    private _api:ApiService,
    private _location:Location
  ) {
    this._ss.newButton = false;
    this._ss.saveButton = true;
    this._ss.listButton = true;

    this.goList = this._ss.$golist.subscribe(()=>{
      this._location.back();
    });

    this.saveExpenseEvent = this._ss.$save.subscribe(() => {
      this.save();
    });

    this._ss.setSectionTitle('Nuevo gasto');

    this.expenseForm = this.formBuilder.group({
      id:[null],
      user_id:[this._ss.getUserSettings().id],
      type:[null, Validators.required],
      description:[null, Validators.required],
      amount:[null, Validators.required],
      date:[null, Validators.required],
      filename:[null]
    });

    this.uploader = new FileUploader({
      url: this._api.uploadUrl,
      method: "POST",
      queueLimit: 1,
      allowedMimeType: ['image/png', 'image/jpeg'],
      additionalParameter:{path:''+this._ss.getUserSettings().company_id+'/'+this._ss.getUserSettings().id+'/'},
      itemAlias: 'photo',
    });
    

    this.response = '';

    let params = this._activatedRouter.snapshot.params;
    if(params.id){
      this.expenseForm.controls.id.setValue((params.id));
      this.isEdit = true;
    } 
   }

  ngOnInit(): void {
    this.getExpenseTypes();
    if(this.expenseForm.controls.id.value) {
      this.title = 'Modificar Gasto';
      this._ss.setSectionTitle(this.title);

      this.getExpenseDetails();
    }

    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; 
      this.filename = file.file.name;
      if (this.uploader.queue.length >= 1) {
        this.saveWithImage = true;            
      }
    };

    this.uploader.onBeforeUploadItem = (file)=>{
      console.log(file);
    };

    this.uploader.onProgressItem = (fileItem: any, progress: any)=>{
      this.isuploadingProgress = true;
       this.uploadStatusProgress = progress+'%';
     }

    this.uploader.onCompleteItem = (item:any, data:any, status:any, headers:any) => {
    data = JSON.parse(data);
      if(data.succes === 'OK'){
        console.log(data);
        let filename = data.file.filename;
        this.expenseForm.controls.filename.setValue(filename);
        this.saveExpense();
      }else{
        console.log("Se ha producido un error");
      }
    }

    this.uploader.onErrorItem = (item: any, response: string, status: number, headers:any) =>{
      console.log(response);
    }
    
  }

  get f() { return this.expenseForm.controls; }

  getExpenseTypes(){
    this._api.getExpenseTypes().subscribe((response)=>{
      if(response.result == 'OK'){
        this.expenseTypes = response.data;
      }
    },((err) => {
      this._ss.errorHandler(err);
    }));
  }

  getExpenseDetails(){
    this._api.getExpenseDetails(this.expenseForm.controls.id.value).subscribe((response)=>{
      if(response.result == 'OK'){
        this.expenseForm.controls.description.setValue(response.data.description);
        this.expenseForm.controls.type.setValue(response.data.type);
        this.expenseForm.controls.date.setValue(moment(response.data.date).format('YYYY-MM-DD\THH:mm:ss'));
        this.expenseForm.controls.amount.setValue(response.data.amount);
        this.expenseForm.controls.filename.setValue(response.data.ticket_img);
      }
      console.log(this.expenseForm.value);
    },((err)=>{
      this._ss.errorHandler(err);
    }));
  }
  

  saveExpense(){
    if(!this.expenseForm.invalid){
     
      if(this.expenseForm.controls.id.value != null){
        this.modifyExpense();
      }else{
        this.addNewExpense();
      } 
    }else{
      this._ns.notify('error','Error: algunas campos son obligatorios.');
    }
  }

  addNewExpense(){
    this._api.addNewExpense(this.expenseForm.value).subscribe((response)=>{
      if(response.result == 'OK'){
        this._ns.notify('success','Guardado correctamente!');
        this.expenseForm.reset();
        this.expenseForm.patchValue({
          id:null,
          user_id:this._ss.getUserSettings().id,
          type:null,
          description:null,
          amount:null,
          date:null,
        });
      }
    },((err) => {
      this._ss.errorHandler(err);
    }));
  }

  modifyExpense(){
    this._api.modifyExpense(this.expenseForm.value).subscribe((response)=>{
      if(response.result == 'OK'){
        this._ns.notify('success','Guardado correctamente!');
      }
    },((err) => {
      this._ss.errorHandler(err);
    }));
  }

  ngOnDestroy(){
    this.goList.unsubscribe();
    this.saveExpenseEvent.unsubscribe();
  }

  showDetails(){
    this.isCollapse = !this.isCollapse;
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];

    console.log(file);

    this.readBase64(file)
      .then(function(data) {
      console.log(data);
    })

  }

  readBase64(file): Promise<any> {
    var reader  = new FileReader();
    var future = new Promise((resolve, reject) => {
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.addEventListener("error", function (event) {
        reject(event);
      }, false);

      reader.readAsDataURL(file);
    });
    return future;
  }

  fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }

  save(){
    console.log(this.saveWithImage);
    if(this.saveWithImage){
      this.uploader.uploadAll();
    }else{
      this.saveExpense();
    }
  }

  showTicket(status){
    this.isTicketDisplayed = status;
  }


}
