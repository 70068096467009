import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CalendarOptions } from '@fullcalendar/common';
import esLocale from '@fullcalendar/core/locales/es';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';
import { Calendar } from '@fullcalendar/core';
import { absencesTypes } from 'src/app/constants';

@Component({
  selector: 'app-holidays-calendar',
  templateUrl: './holidays-calendar.component.html',
  styleUrls: ['./holidays-calendar.component.scss']
})
export class HolidaysCalendarComponent implements OnInit {

  @Input() absences;

  eventColors = absencesTypes;

  locales = [esLocale];

  events = [];

  range =  new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    locale:esLocale,
    headerToolbar:{
      left:'prev,next,today',
      center:'title',
      right:'dayGridMonth,dayGridWeek,dayGridDay'
    },
    dayMaxEvents:true,   
    events:this.events
  };


  constructor(
    private _api: ApiService
  ){
   this.range.controls.start.setValue(moment().clone().startOf('month').format('YYYY-MM-DD'));
   this.range.controls.end.setValue(moment().clone().endOf('month').format('YYYY-MM-DD'));
  }

  ngOnInit(){
    console.log(this.absences);
    this.getEvents(); 
  }

  getEvents(){
    this._api.getCompanyHolidays().subscribe((response)=>{
      console.log(response);
      this.events = response.data.map((event)=>{
        console.log(event);
        let absence;
        if(event.id_absences_type != 2){
          absence = {title: event.user_name+': '+event.description, start:moment(event.date_start).format('YYYY-MM-DD'), end:moment(event.date_end).format('YYYY-MM-DD'), color: this.getEventcolor(event.id_absences_type)}
        }else{
          absence = {title: event.observations, start:moment(event.date_start).format('YYYY-MM-DD'), end:moment(event.date_end).format('YYYY-MM-DD'), color: this.getEventcolor(event.id_absences_type)}
        }
        console.log(absence);
        this.events.push(absence);
      });
      
    });
   
  }

  handleDateClick(arg) {
    alert('date click! ' + arg.dateStr)
  }

  eventClick(e){
    console.log(e);
  }

  getEventcolor(id){
    return this.eventColors.find(x => x.id == id).color;
  }


}